import TableRow from "../TableRow/TableRow.jsx";
import React, { useEffect, useState } from "react";

const Table = ({ children, items, itemsSummary, selectedStore }) => {
    const [ItemsArray, setItemsArray] = useState();
    const [isAllStores, setIsAllStores] = useState();
    let { store_type } = itemsSummary;

    useEffect(() => {
        if (store_type === 10) {
            setIsAllStores(true);
        } else {
            setIsAllStores(false);
        }
    }, [selectedStore]);

    useEffect(() => {
        if (items?.length > 0 && typeof isAllStores !== 'undefined') {
            setItemsArray(items.map((item, i) => {
                return (
                    <TableRow item={item} isAllStores={isAllStores} store_type={store_type} />
                );
            }));
        }
    }, [items, isAllStores, store_type]);

    return (
        <>
            <table>
                <tr>
                    {isAllStores ? <th>Магазин</th> : ''}
                    <th>Наименование товара</th>
                    <th>Сумма заказов, руб.</th>
                    <th>Доля в обороте, %</th>
                    <th>Совокупный процент, %</th>
                    <th>Группа</th>
                </tr>

                {ItemsArray}
            </table>
            {children}
        </>
    );

};

export default Table;
import "./Auth.css";
import "../Button/Button.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from "react";
import Alert from "../Alert/Alert";
import { Turnstile } from '@marsidev/react-turnstile';

const Auth = ({ isLogin, isReset = false, uuid = null, handleForm, setIsLoggedIn, isLoggedIn }) => {
    const navigate = useNavigate();

    const { register, handleSubmit, watch, formState: { errors, isValid } } = useForm({ mode: 'onChange' });

    const email = React.useRef();
    const password = React.useRef();
    const turnstileRef = React.useRef(null); // реф для сброса капчи

    const [alert, setAlert] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [token, setToken] = useState('');

    const formSubmit = (values) => {
        if (!token) {
            setAlert({ isDisplay: true, message: 'Пройдите проверку капчи', type: 'danger' });
            return;
        }

        setIsSubmitting(true);

        const formData = {
            cf_token: token,
        };

        // Добавляем поле password только если оно не пустое
        if (password) {
            formData.password = values.password;
        }

        if (email) {
            formData.email = values.email;
        }

        if (uuid) {
            formData.uuid = uuid;
        }

        handleForm(formData).then((data) => {
            setAlert({ isDisplay: true, message: data.message, type: 'success' });

            if (data?.token) {
                localStorage.setItem("jwt", data.token);
                setIsLoggedIn(true);
                navigate('/');
            }
        }).catch((err) => {
            setAlert({ isDisplay: true, message: err.message, type: 'danger' });
        }).finally(function () {
            setIsSubmitting(false);
            setToken(''); // сброс токена
            if (turnstileRef.current) {
                turnstileRef.current.reset(); // сброс капчи
            }
        })
    }

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/dashboard');
        }
    }, [isLoggedIn])

    return (
        <>
            <div className="auth">
                <span class="mask"></span>
                <NavLink to="/" className="auth__logo" />

                <div className="auth__container">
                    <div className="auth__form">
                        <form className="auth__form-elements" onSubmit={handleSubmit(formSubmit)}>
                            <Alert alert={alert} />

                            {(!isReset || (isReset && uuid === null)) && (
                                <div className="form-group">
                                    <label className="form__label form__label_mt">Email</label>
                                    <input className="form__input" name="email" placeholder="Введите ваш email"
                                        ref={email} {...register("email", {
                                            required: 'Заполните поле',
                                            pattern: {
                                                value: /.+@[^@]+\.[^@]{2,}$/,
                                                message: 'Невалидный email'
                                            }
                                        })}
                                    />
                                    {errors.email && <label className="error-message">{errors.email.message}</label>}
                                </div>
                            )}

                            {(!isReset || (isReset && uuid !== null)) && (
                                <div className="form-group">
                                    <label className="form__label form__label_mt">{isReset && uuid !== null ? 'Новый пароль' : 'Пароль'}</label>
                                    <input className="form__input" name="password" placeholder="Введите ваш пароль"
                                        ref={password} type="password" required
                                        {...register("password", {
                                            required: 'Заполните поле',
                                            minLength: {
                                                value: 6,
                                                message: 'Минимум 6 символов'
                                            },
                                        })} />
                                    {errors.password && <label className="error-message">{errors.password.message}</label>}
                                </div>
                            )}

                            {isReset && uuid !== null &&
                                <div className="form-group">
                                    <label className="form__label form__label_mt">Повторите пароль</label>
                                    <input className="form__input" name="confrm_password" placeholder="Введите ваш пароль снова"
                                        type="password" required
                                        {...register("confrm_password", {
                                            required: 'Заполните поле',
                                            validate: value => value === watch('password') || 'Пароли не совпадают'
                                        })} />
                                    {errors.confrm_password && <label className="error-message">{errors.confrm_password.message}</label>}
                                </div>
                            }

                            <button className="button auth__button" disabled={isSubmitting || !isValid}>{isLogin ? 'Войти' : (isReset ? 'Сбросить пароль' : 'Зарегистрироваться')}</button>
                            <div className="form-group">
                                <Turnstile
                                    ref={turnstileRef} // добавлен реф для сброса капчи
                                    siteKey="0x4AAAAAAAtZSgv69vKidhXO"
                                    options={{
                                        action: 'submit-form',
                                        theme: 'light',
                                        size: 'flexible',
                                    }}
                                    onSuccess={(token) => setToken(token)} // Получаем токен
                                    onError={() => setToken('')} // сбрасываем токен при ошибке
                                    onExpire={() => setToken('')} // сбрасываем токен при истечении срока действия капчи
                                />
                            </div>
                        </form>

                        {!isReset && (
                            !isLogin ? (
                                <span className="auth__text">
                                    Уже зарегистрированы?{'\u00A0'}
                                    <NavLink to="/signin">Войти</NavLink>
                                </span>
                            ) : (
                                <span className="auth__text">
                                    Еще не зарегистрированы?{'\u00A0'}
                                    <NavLink to="/signup">Регистрация</NavLink>
                                </span>
                            )
                        )}

                        {!isReset && (
                            <span className="auth__text">
                                Забыли пароль?{'\u00A0'}
                                <NavLink to="/reset-password" >Сбросить</NavLink>
                            </span>
                        )}


                    </div>
                </div>

            </div >
        </>
    );
};

export default Auth;
import Header from '../Header/Header';
import Container from '../Container/Container';
import "../Button/Button.css";
import "../Form/Form.css";
import "../NewStore/NewStore.css";
import { useForm } from 'react-hook-form';
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';

const EditStore = ({ isLoggedIn, toast, selectedDate, selectedStore, userStores, handleEditUserStore }) => {
    const { register, handleSubmit, reset, formState: { errors, isValid } } = useForm({ mode: 'onChange' });
    const navigate = useNavigate();

    const store_name = React.useRef();
    const store_api_key = React.useRef();
    const gov_taxes = React.useRef();

    const [isStoreAvailable, setIsStoreAvailable] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const { store_id } = useParams();

    useEffect(() => {
        if (userStores) {
            userStores.map((item, i) => {
                if (parseInt(item.store_id) === parseInt(store_id)) {
                    setIsStoreAvailable(true);
                    let defaultValues = {};
                    defaultValues.store_name = item.store_name;
                    defaultValues.store_id = store_id;
                    defaultValues.gov_taxes = item.gov_taxes;
                    reset({ ...defaultValues });
                }
            })
        }
    }, [userStores])

    const formSubmit = (values) => {
        const id = toast.loading("Применяем изменения..");
        setIsSubmitting(true);

        handleEditUserStore(values).then(async (data) => {
            toast.update(id, {
                render: "Магазин успешно отредактирован",
                autoClose: 5000, type: "success", icon: "🚀",
                isLoading: false, hideProgressBar: false, closeOnClick: true,
                pauseOnHover: true, draggable: true
            });
            navigate("/myStores");
        }).catch((err) => {
            console.log(err)
            toast.update(id, {
                render: err.message,
                autoClose: 5000, type: "error", icon: "🤯",
                isLoading: false, hideProgressBar: false, closeOnClick: true,
                pauseOnHover: true, draggable: true
            });
        }).finally(() => {
            setIsSubmitting(false);
        })
    }

    return (
        <>
            <Header
                selectedDate={selectedDate}
                selectedStore={selectedStore}
            />
            <div className="app__container app__container-margin">
                <h1>Редактирование магазина</h1>
                {isStoreAvailable ?
                    <>
                        <Container>
                            <form onSubmit={handleSubmit(formSubmit)}>
                                <div className="form-group">
                                    <label className="form__label">Название магазина</label>
                                    <input className="form__input form__input_max" name="store_name" placeholder="Введите название магазина"
                                        ref={store_name} type="text" required
                                        {...register("store_name", {
                                            required: 'Заполните поле',
                                            minLength: {
                                                value: 2,
                                                message: 'Минимум 2 символа'
                                            },
                                        })}
                                    ></input>
                                    {errors.store_name && <label className="error-message">{errors.store_name.message}</label>}
                                </div>
                                <div className="form-group">
                                    <label className="form__label">API ключ</label>
                                    <input className="form__input form__input_max" name="store_api_key" placeholder="Вставьте сгенерированный API ключ"
                                        ref={store_api_key} type="text"
                                        {...register("store_api_key")}></input>
                                    <label className='form__label-info'>Оставьте поле пустым если не хотите обновлять APi ключ</label>
                                </div>
                                <div className="form-group">
                                    <label className="form__label">Налоговая ставка</label>
                                    <section  >
                                        <select className="form__select" ref={gov_taxes} {...register("gov_taxes")}>
                                            <option value="0">0% (Не учитывать)</option>
                                            <option value="1">1%</option>
                                            <option value="2">2%</option>
                                            <option value="3">3%</option>
                                            <option value="4">4%</option>
                                            <option value="5">5%</option>
                                            <option value="6">6%</option>
                                            <option value="7">7%</option>
                                            <option value="8">8%</option>
                                            <option value="9">9%</option>
                                            <option value="10">10%</option>
                                            <option value="11">11%</option>
                                            <option value="12">12%</option>
                                            <option value="13">13%</option>
                                            <option value="14">14%</option>
                                            <option value="15">15%</option>
                                            <option value="16">16%</option>
                                            <option value="17">17%</option>
                                            <option value="18">18%</option>
                                            <option value="19">19%</option>
                                            <option value="20">20%</option>
                                            <option value="21">21%</option>
                                            <option value="22">22%</option>
                                            <option value="23">23%</option>
                                            <option value="24">24%</option>
                                            <option value="25">25%</option>
                                        </select>
                                    </section>
                                </div>
                                <div className="form-group">
                                    <button className="button" disabled={!isValid || isSubmitting}>Сохранить изменения</button>
                                </div>
                            </form>
                        </Container>
                    </>
                    :
                    <></>
                }
            </div>
        </>
    );
};

export default EditStore;
import Alert from "../Alert/Alert";

const AlertService = ({ currentUser }) => {
    return (
        <>
            {
                !currentUser?.subscribe?.is_active && currentUser?.user_info?.email !== null ? (
                    <Alert alert={{
                        isDisplay: true,
                        buttonText: 'Перейти к тарифам',
                        buttonLink: '/newStore',
                        title: 'Активируйте подписку',
                        message: 'Чтобы пользоваться сервисом приобретите подписку',
                        type: 'warning-light'
                    }} />
                ) : null
            }

            {
                currentUser?.subscribe?.is_active && !currentUser?.user_info?.is_activated ? (
                    <Alert
                        alert={{
                            isDisplay: true,
                            title: 'Активируйте аккаунт',
                            message: 'Чтобы не потерять доступ к аккаунту, перейдите по ссылке отправленной на ваш почтовый ящик. Если письмо не пришло, проверьте папку "Спам".',
                            type: 'warning-light'
                        }}
                    />
                ) : null
            }

            {
                currentUser?.subscribe?.is_active === true && currentUser?.stores?.length === 1 ? (
                    <Alert alert={{
                        isDisplay: true,
                        buttonText: 'Добавить магазин',
                        buttonLink: '/newStore',
                        title: 'Добавьте свой первый магазин',
                        message: 'Для начала работы с сервисом, добавьте свой первый магазин.', type: 'info-light'
                    }} />
                ) : currentUser?.subscribe?.is_active === true && currentUser?.stores?.length > 1 ? (
                    <Alert alert={{
                        isHiddable: true, hid: 'cost-pricess', isDisplay: true,
                        buttonText: 'Задать себестоимость',
                        buttonLink: '/items',
                        title: 'Задайте себестоимость товаров',
                        message: `Чтобы статистика (ROI, маржинальность, рентабельность) рассчитывалась корректно, укажите себестоимость товаров.`,
                        type: 'info-light'
                    }} />
                ) : null
            }

        </>
    )
}

export default AlertService;
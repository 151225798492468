import "./Header.css";
import "../Icon/Icon.css";
import "../Dropdown/Dropdown.css";

import { NavLink, useLocation } from "react-router-dom";
import { PiGear as PiWrench } from "react-icons/pi";
const Header = ({ selectedStore, selectedDate }) => {
    const location = useLocation().pathname;

    const getNavUrl = (pathPrefix) => {
        return `${pathPrefix}/${selectedStore?.value || 0}`;
    };

    const isOrdersActive = new RegExp(`^${getNavUrl('/orders')}`).test(location);
    const isOrdersMapActive = location === '/orders-map';
    const isStoresActive = ['/myStores', '/editStore', '/newStore'].some(path => location?.startsWith(path));

    const logoutUser = () => {
        localStorage.clear();
        window.location.href = process.env.REACT_APP_API_URL + '/logout';
    };

    return (
        <header className="header">
            <div className="header__container">
                <nav className="header__parent">
                    <NavLink to={`/dashboard/${selectedStore?.value || 0}/date/${selectedDate?.value || '1m'}`} >
                        <div className="header__logo"></div>
                    </NavLink>

                    <ul className="header__links">
                        {location === '/' ?
                            <>
                                <NavLink className="header__link" to="/" >Инструменты</NavLink>
                                <NavLink className="header__link" to="/" >Возможности</NavLink>
                                <NavLink className="header__link" to="/" >Тарифы</NavLink>
                            </>
                            :
                            <>
                                <NavLink className={
                                    location.startsWith(`/dashboard`) ? 'header__link header__link-active' : 'header__link'
                                }
                                    to={`/dashboard/${selectedStore?.value || '0'}/date/${selectedDate?.value || '1m'}`} >
                                    <span className="header__link-text">Дашборд</span>
                                </NavLink>
                                <NavLink className={
                                    location.startsWith(`/items`) ? 'header__link header__link-active' : 'header__link'
                                } to={getNavUrl('/items') + '/page/1'} >
                                    <span className="header__link-text">Товары</span>
                                </NavLink>
                                <NavLink className={
                                    isOrdersActive ? 'header__link header__link-active' : 'header__link'
                                } to={getNavUrl('/orders') + '/page/1'} >
                                    <span className="header__link-text">Лента заказов</span>
                                </NavLink>
                                <NavLink className={
                                    isOrdersMapActive ? 'header__link header__link-active' : 'header__link'
                                } to="/orders-map" >
                                    <span className="header__link-text">Карта заказов</span>
                                </NavLink>
                                <NavLink className={
                                    location.startsWith(`/abc-analysis`) ? 'header__link header__link-active' : 'header__link'
                                } to={getNavUrl('/abc-analysis') + `/page/1/date/${selectedDate?.value || '1m'}`} >
                                    <span className="header__link-text">ABC анализ</span>
                                </NavLink>
                                <NavLink className={
                                    location.startsWith(`/tariffs`) ? 'header__link header__link-active' : 'header__link'
                                } to={'/tariffs'} >
                                    <span className="header__link-text">Тарифы</span>
                                </NavLink>
                            </>}
                    </ul>
                </nav>
                <ul className="header__auth header__links">
                    <>
                        <NavLink className={
                            isStoresActive ? 'header__link header__link-active' : 'header__link'
                        } to="/myStores" >
                            Мои магазины
                        </NavLink>

                        <div class="dropdown">
                            <span className={
                                location.startsWith(`/account`) ? 'header__link header__link-active' : 'header__link'
                            }  >< PiWrench className="h-icon" /></span>
                            <div class="dropdown-content">
                                <NavLink to="/account" >Настройки</NavLink>
                                <NavLink onClick={logoutUser} >Выйти</NavLink>
                            </div>
                        </div>
                    </>
                </ul>
            </div>
        </header>
    );
};

export default Header;
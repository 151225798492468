import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";

import Container from '../Container/Container';
import Header from '../Header/Header';

import "./SpecialPage.css";

const Activate = ({ currentUser, selectedDate, selectedStore, handleUserActivation }) => {
    const { token } = useParams();
    const [textResult, setTextResult] = useState('Активируем аккаунт...');

    useEffect(() => {
        if (currentUser.user_info.email != null) {
            handleUserActivation({ uuid: token }).then(r => {
                setTextResult('Аккаунт активирован');
            }).catch((err) => {
                setTextResult(err.message);
            })
        }
    }, [currentUser, token]);


    return (
        <>
            <Header
                selectedDate={selectedDate}
                selectedStore={selectedStore}
            />

            <div className="app__container app__container-margin">
                <Container >
                    <div  >
                        <h1>{textResult}</h1>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default Activate;
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import Header from '../Header/Header';
import Container from '../Container/Container';
import "../Button/Button.css";
import "../Form/Form.css";
import "./NewStore.css";
import CollapseSection from '../Collapse/Collapse.jsx';

const NewStore = ({ toast, handleNewUserStore, selectedDate, selectedStore }) => {
    const { register, watch, handleSubmit, setValue, formState: { errors, isValid } } = useForm({ mode: 'onChange' });
    const navigate = useNavigate();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [apiRegex, setApiRegex] = useState('');
    const [y_stores, setY_stores] = useState([]);
    const [isClientIdShow, setIsClientIdShow] = useState(false);
    const [isStoreNameShow, setIsStoreNameShow] = useState(true);
    const [isApiKeyDisabled, setIsApiKeyDisabled] = useState(false);

    const toastErrConfig = (msg) => ({
        render: msg, autoClose: 5000, type: "error", icon: "🤯",
        isLoading: false, hideProgressBar: false, closeOnClick: true,
        pauseOnHover: true, draggable: true
    });

    const formSubmit = async (values) => {
        const id = toast.loading("Добавляем магазин..");
        setIsSubmitting(true);

        if (Number(values.store_type) !== 1) {
            delete values.store_api_client_id;
        }

        try {
            const data = await handleNewUserStore(values);
            if (data.is_preview) {
                toast.update(id, { autoClose: 1, isLoading: false });
                setY_stores(data.result);
            } else {
                toast.update(id, {
                    render: "Магазин успешно добавлен и ожидает синхронизации",
                    autoClose: 5000, type: "success", icon: "🚀",
                    isLoading: false, hideProgressBar: false, closeOnClick: true,
                    pauseOnHover: true, draggable: true
                });
                navigate("/myStores?message=success");
            }
        } catch (err) {
            toast.update(id, toastErrConfig(err.message));
        } finally {
            setIsSubmitting(false);
        }
    };

    const onChangemp = (event) => {
        const value = Number(event.target.value);

        if (value === 0) {
            setApiRegex(/(^[\w-]*\.[\w-]*\.[\w-]*$)/gi);
            setIsClientIdShow(false);
            setIsStoreNameShow(true);
        } else if (value === 1) {
            setApiRegex(/^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/gi);
            setIsClientIdShow(true);
            setIsStoreNameShow(true);
        } else if (value === 2) {
            setApiRegex(/^y[0-3]_[-_A-Za-z0-9]{10,200}$/gi);
            setIsClientIdShow(false);
            setIsStoreNameShow(false);
        }
    };

    useEffect(() => {
        const hash = window.location.hash;
        const hashParams = new URLSearchParams(hash.substring(1));
        const token = hashParams.get('access_token');

        if (token) {
            setValue("store_type", "2");
            setValue("store_api_key", token);

            setIsStoreNameShow(false);
            setIsApiKeyDisabled(true);

            onChangemp({ target: { value: 2 } });

            // Optional: Trigger form submission automatically if needed
            formSubmit({ store_type: 2, store_api_key: token });

            // Clear the hash from URL
            window.history.pushState({}, null, window.location.pathname);
        }
    }, [setValue, onChangemp, formSubmit]);

    const handleGetKeyClick = (event) => {
        event.preventDefault();

        if (watch("store_type") !== "2") {
            return;
        }

        window.location.href = "https://oauth.yandex.ru/authorize?response_type=token&client_id=c6199df054b848b1ad5b619908b32229";
    };

    return (
        <>
            <Header selectedStore={selectedStore} selectedDate={selectedDate} />
            <div className="app__container app__container-margin">
                <h1>Новый магазин</h1>
                <Container>
                    <form onSubmit={handleSubmit(formSubmit)}>
                        <div className="form-group select-mp" onChange={onChangemp}>
                            <label className="form__label">Выберите маркетплейс</label>
                            <div className="form_radio_btn">
                                <input id="radio-0" type="radio" name="store_type" value="0" {...register("store_type")} />
                                <label htmlFor="radio-0">Wildberries</label>
                            </div>
                            <div className="form_radio_btn">
                                <input id="radio-1" type="radio" name="store_type" value="1" {...register("store_type")} />
                                <label htmlFor="radio-1">Ozon</label>
                            </div>
                            <div className="form_radio_btn">
                                <input id="radio-2" type="radio" name="store_type" value="2" {...register("store_type")} />
                                <label htmlFor="radio-2">Яндекс Маркет</label>
                            </div>
                        </div>

                        {isStoreNameShow &&
                            <div className="form-group">
                                <label className="form__label">Название магазина</label>
                                <input className="form__input form__input_max" name="store_name" placeholder="Введите название магазина"
                                    type="text" required
                                    disabled={!watch("store_type")}
                                    {...register("store_name", {
                                        required: 'Заполните поле',
                                        minLength: {
                                            value: 2,
                                            message: 'Минимум 2 символа'
                                        },
                                    })}
                                />
                                {errors.store_name && <label className="error-message">{errors.store_name.message}</label>}
                            </div>
                        }

                        {isClientIdShow &&
                            <div className="form-group">
                                <label className="form__label">Client ID</label>
                                <input className="form__input form__input_max" name="store_api_client_id" placeholder="Вставьте client id"
                                    type="text" required
                                    disabled={!watch("store_type")}
                                    {...register("store_api_client_id", {
                                        required: 'Заполните поле',
                                        pattern: {
                                            value: /^(0|[1-9]\d*)(\.\d+)?$/,
                                            message: 'Введите валидный Client ID'
                                        },
                                    })}
                                />
                                {errors.store_api_client_id && <label className="error-message">{errors.store_api_client_id.message}</label>}
                            </div>
                        }

                        <div className="form-group">
                            <label className="form__label">API ключ</label>
                            <input className="form__input form__input_max" name="store_api_key" placeholder="Вставьте сгенерированный API ключ"
                                type="text" required
                                disabled={isApiKeyDisabled || !watch("store_type")}
                                {...register("store_api_key", {
                                    required: 'Заполните поле',
                                    minLength: {
                                        value: 10,
                                        message: 'Минимум 10 символов'
                                    },
                                    pattern: {
                                        value: apiRegex,
                                        message: 'Введите валидный API ключ'
                                    }
                                })}
                            />
                            {errors.store_api_key && <label className="error-message">{errors.store_api_key.message}</label>}
                        </div>

                        {!isStoreNameShow && y_stores.length !== 0 &&
                            <div className="form-group">
                                <label className="form__label">Выберите магазин</label>
                                <div className="yid-form">
                                    {y_stores.map((e, i) => (
                                        <label htmlFor={'radio_yid-' + i} key={i}>
                                            <div className="form_radio_btn">
                                                <input id={'radio_yid-' + i} type="radio" name="store_y_id" value={e.id} {...register("store_y_id")} />
                                                <label htmlFor={'radio_yid-' + i} className="form yid-label">
                                                    <h2>{e.name}</h2>
                                                    <p>{e.types}</p>
                                                </label>
                                            </div>
                                        </label>
                                    ))}
                                </div>
                            </div>
                        }

                        <div className="form-group form-group_btn">
                            <button className="button btn_submit" type="submit" disabled={isSubmitting || !isValid}>
                                {isSubmitting ? 'Добавление...' : 'Добавить магазин'}
                            </button>

                            {watch("store_type") === "2" && !isApiKeyDisabled && (
                                <button className="button btn_special_api" type="button" onClick={handleGetKeyClick}>
                                    Получить ключ
                                </button>
                            )}
                        </div>

                    </form>
                </Container>
                <h2 className='help-text'>Как получить API ключ?</h2>
                <Container>
                    <CollapseSection title="Инструкция для Wildberries">
                        <p>1. Перейдите по ссылке на <a href="https://seller.wildberries.ru/supplier-settings/access-to-api" target="_blank" rel="noreferrer">портал поставщика Wildberries</a>.</p>
                        <p>2. Убедитесь что вы находитесь на вкладке "Доступ к API".</p>
                        <p>3. Нажмите кнопку "Создать новый токен".</p>
                        <p><img src={`${process.env.PUBLIC_URL}/faq/wb_scr_1.png`} alt="Инструкция Wildberries 1" /></p>
                        <p>4. В поле "Имя токена" введите любое название, выберите галочку "Только чтение" и отметьте опции "Контент", "Статистика" и "Аналитика".</p>
                        <p><img src={`${process.env.PUBLIC_URL}/faq/wb_scr_2.png`} alt="Инструкция Wildberries 2" /></p>
                        <p>5.Нажмите кнопку "Создать токен", затем скопируйте его и вставьте в поле "API ключ" на нашем сайте.</p>
                    </CollapseSection>

                    <CollapseSection title="Инструкция для Ozon">
                        <p>1. Перейдите по <a href="https://seller.ozon.ru/app/settings/api-keys?currentTab=sellerApi" target="_blank" rel="noreferrer">ссылке Ozon Seller</a>.</p>
                        <p>2. Скопируйте Client ID и вставьте его на нашем сайте.</p>
                        <p>3. Нажмите на кнопку "Сгенерировать ключ".</p>
                        <p><img src={`${process.env.PUBLIC_URL}/faq/oz_scr_1.png`} alt="Инструкция Ozon 1" /></p>
                        <p>4. В появившемся окне найдите чекбокс Admin read only, в название ключа введите любое имя.  Нажмите на кнопку Сгенерировать.</p>
                        <p><img src={`${process.env.PUBLIC_URL}/faq/oz_scr_2.png`} alt="Инструкция Ozon 2" /></p>
                        <p>5. Скопируйте полученный ключ и вставьте его на нашем сайте.</p>
                    </CollapseSection>

                    <CollapseSection title="Инструкция для Яндекс Маркет">
                        <p>Важно: вы должны быть авторизованы в Яндексе под своим аккаунтом, у которого есть доступ к вашему магазину.</p>
                        <p>&nbsp;</p>
                        <p>1. Нажмите на чекбокс "Яндекс Маркет".</p>
                        <p>2. Нажмите на кнопку "Получить ключ".</p>
                        <p>3. Подтвердите доступ приложения к вашему аккаунту Яндекса.</p>
                        <p>4. Поле API ключ заполнится автоматически.</p>
                    </CollapseSection>
                </Container>
            </div >
        </>
    );
};

export default NewStore;

export default class Api {
  constructor(options) {
    this._url = process.env.REACT_APP_API_URL || options.baseUrl;
  }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    return res.json().then((r) => Promise.reject(r));
  }

  getCsrfToken() {
    const name = 'csrf=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return '';
  }

  // Метод для генерации заголовков, включая актуальный CSRF токен
  _getHeaders() {
    return {
      'Content-Type': 'application/json',
      'x-csrf-token': this.getCsrfToken(),  // Получение актуального CSRF токена
    };
  }

  signIn(data) {
    return fetch(`${this._url}/signin`, {
      method: 'POST',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  signUp(data) {
    return fetch(`${this._url}/signup`, {
      method: 'POST',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  getUserInfo(data) {
    return fetch(`${this._url}/getUserInfo`, {
      method: 'GET',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  getUserStores(data) {
    return fetch(`${this._url}/getUserStores`, {
      method: 'GET',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  newUserStore(data) {
    return fetch(`${this._url}/newUserStore`, {
      method: 'POST',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  editUserStore(data) {
    return fetch(`${this._url}/editUserStore`, {
      method: 'POST',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  deleteUserStore(data) {
    return fetch(`${this._url}/deleteUserStore`, {
      method: 'POST',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  getUserOrders(data) {
    return fetch(`${this._url}/getUserOrders`, {
      method: 'POST',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  getUserItems(data) {
    return fetch(`${this._url}/getUserItems`, {
      method: 'POST',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  setCostPrice(data) {
    return fetch(`${this._url}/setCostPrice`, {
      method: 'POST',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  getChartOrders(data) {
    return fetch(`${this._url}/getCharts.Orders`, {
      method: 'POST',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  getDashboardExtended(data) {
    return fetch(`${this._url}/getDashboard.Extended`, {
      method: 'POST',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  getAbcAnalysis(data) {
    return fetch(`${this._url}/getAnalytics.ABC`, {
      method: 'POST',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  getTgToken(data) {
    return fetch(`${this._url}/tg.getToken`, {
      method: 'GET',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  getTgSettings(data) {
    return fetch(`${this._url}/tg.getSettings`, {
      method: 'GET',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  setTgSettings(data) {
    return fetch(`${this._url}/tg.setSettings`, {
      method: 'POST',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  getLastetOrders(data) {
    return fetch(`${this._url}/getLastetOrders`, {
      method: 'POST',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  getUserActivation(data) {
    return fetch(`${this._url}/activate`, {
      method: 'POST',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  getUserTransactions(data) {
    return fetch(`${this._url}/getUserTransactions`, {
      method: 'POST',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  getTariffsCalculated() {
    return fetch(`${this._url}/getTariffsCalculated`, {
      method: 'GET',
      headers: this._getHeaders(),
      credentials: 'include',
    }).then(this._checkResponse);
  }

  getResetPasswordRequest(data) {
    return fetch(`${this._url}/reset-password-request`, {
      method: 'POST',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }

  getResetPassword(data) {
    return fetch(`${this._url}/reset-password`, {
      method: 'POST',
      headers: this._getHeaders(),
      credentials: 'include',
      body: JSON.stringify(data),
    }).then(this._checkResponse);
  }
}

import Header from '../Header/Header';
import Container from '../Container/Container';
import "../Form/Form.css";
import { useEffect, useState } from 'react';
import StoreList from './Store-list/Store-list';
import TransactionList from './TransactionList/TransactionList';
import ReactPaginate from "react-paginate";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import QRCode from "react-qr-code";
import AlertService from '../AlertService/AlertService.jsx';

const Account = ({ currentUser, selectedStore, selectedDate, getTgToken, getTgSettings, setTgSettings, getUserTransactions, toast }) => {
    const [disabled, setDisabled] = useState(true);
    const [tgToken, setTgToken] = useState(true);
    const [tgData, setTgData] = useState(false);
    const [storeData, setStoreData] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [DataTransactionList, setDataTransactionList] = useState(false);

    const handleGetTxsList = (page_num) => {
        getUserTransactions({ page_num }).then((data) => {
            setDataTransactionList(data);
        }).catch((e) => {
            console.log(e)
        })
    }

    const handleClickPagination = (event) => {
        handleGetTxsList(parseInt(event.selected));
    }

    useEffect(() => {
        getTgToken().then((data) => {
            if (data?.is_request_token) {
                setDisabled(false);
                setTgToken(data.result.uuid);
                setIsLoading(false);
            } else {
                setTgData(data.result);

                getTgSettings().then((data) => {
                    console.log('loaded settings', data)
                    if (data?.result) {
                        setStoreData(data.result);
                        console.log(data.result)
                        setIsLoading(false);
                    } else {
                        //
                    }
                }).catch((e) => {
                    console.log(e)
                })
            }
        }).catch((e) => {
            console.log(e)
        })

        handleGetTxsList(0);
    }, [])

    return (
        <>
            <Header
                selectedStore={selectedStore}
                selectedDate={selectedDate}
            />
            <div className='app__container app__container-margin'>
                <AlertService
                    currentUser={currentUser}
                />
                <h1>Настройки</h1>
                <div className="container-row">
                    <Container className={'w_half'}>
                        <h2>Почта</h2>
                        <label className="form__label">Email {currentUser.user_info.is_activated ? 'подтвержден' : 'не подтвержден. Проверьте свой почтовый ящик.'}</label>
                        <input className="form__input form__input_max form__label_bottom" defaultValue={currentUser.user_info.email} disabled></input>
                        <h2>Подписка</h2>
                        <label className="form__label form__label_bottom">{currentUser?.subscribe?.is_active ? `Подписка активна до ${currentUser?.subscribe?.expires_at}` : 'Подписка не активна'}</label>
                    </Container>
                    <Container className={'w_half'}>
                        <h2>Уведомления в Telegram</h2>
                        {isLoading ?
                            <Skeleton height="2rem" style={{ 'margin-bottom': "1rem" }} count={5} />
                            :
                            <>
                                <label className="form__label form__label_bottom">Уведомления {tgData ? null : 'не'} подключены. {tgData ? `Аккаунт @${tgData.tg_nickname}` : null}</label>
                                {tgData ? (
                                    tgData?.is_active ? (
                                        <span>Выберите магазины для подключения уведомлений:</span>
                                    ) : (
                                        <span>У вас нет активных магазинов.</span>
                                    )
                                ) : null}

                                <div className="form-group">
                                    {tgData ?
                                        <>
                                            <StoreList
                                                StoreList={storeData}
                                                setTgSettings={setTgSettings}
                                                toast={toast}
                                            />
                                        </>
                                        :
                                        <>
                                            <span>Для подключения уведомлений: отсканируйте QR или перейдите по ссылке и нажмите начать в телеграм.</span>
                                            <div className="form-group">
                                                <QRCode
                                                    size={56}
                                                    style={{ height: "auto", maxWidth: "100%", width: "126px", padding: '5px 5px' }}
                                                    value={`https://t.me/ziffar_bot?start=${tgToken}`}
                                                    viewBox={`0 0 256 256`}
                                                />

                                                <a href={`https://t.me/ziffar_bot?start=${tgToken}`} target="_blank">
                                                    <button className="button" disabled={disabled}>Добавить аккаунт</button>
                                                </a>
                                            </div>
                                        </>
                                    }
                                </div>
                            </>
                        }
                    </Container>
                </div >

                <h1 id="transactions">Транзакции</h1>
                <Container>
                    {DataTransactionList?.summary?.items_count_total > 0 ?
                        <>
                            <TransactionList
                                TransactionList={DataTransactionList}
                            />
                        </>
                        : 'Ничего не найдено'
                    }

                    {(Math.ceil(DataTransactionList?.summary?.items_count_total / DataTransactionList?.summary?.limit) > 1) &&
                        <ReactPaginate
                            containerClassName="pagination"
                            activeClassName="active"
                            pageClassName="page-item"
                            onPageChange={handleClickPagination}
                            pageCount={(Math.ceil(DataTransactionList?.summary?.items_count_total / DataTransactionList?.summary?.limit))}
                            previousClassName={"item previous"}
                            previousLabel={<MdKeyboardArrowLeft />}
                            nextClassName={"item next"}
                            nextLabel={<MdKeyboardArrowRight />}
                        />
                    }

                </Container>
            </div>
        </>
    );
};

export default Account;
import Auth from '../Auth';

const Register = ({ handleForm, setIsLoggedIn, isLoggedIn }) => {
    return (
        <Auth
            isLogin={false}
            handleForm={handleForm}
            setIsLoggedIn={setIsLoggedIn}
            isLoggedIn={isLoggedIn}
        />
    );
};

export default Register;
import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { getPlural, humanNum } from '../../utils/plural';

import Header from '../Header/Header';
import Container from '../Container/Container';
import Package from './Package/Package.jsx';
import Packages from '../../utils/ActualPackages.json';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import SweetAlert2 from 'react-sweetalert2';

import "../SpecialPage/SpecialPage.css";
import "./Tariffs.css";
import "../Icon/Icon.css";

import CloudPayments from '../pay/CloudPayments/CloudPayments.jsx'; // Путь к вашему хуку
import { FiShoppingCart } from "react-icons/fi";

const Tariffs = ({ getTariffsCalculated, currentUser, selectedStore, selectedDate, toast, navigate }) => {
    const { register, handleSubmit, formState: { errors, isValid } } = useForm({ mode: 'onChange' });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selected, setSelected] = useState(Packages?.packages[0]);
    const [currentPackage, setCurrentPackage] = useState({});
    const [tariffDescription, setTariffDescription] = useState('');

    const [tariffCalculated, setTariffCalculated] = useState([]);
    const [tariffCalculatedCurrent, setTariffCalculatedCurrent] = useState({});
    const [isMasterChecked, setIsMasterChecked] = useState(false);
    const [isCheckbox1Checked, setIsCheckbox1Checked] = useState(false);
    const [isCheckbox2Checked, setIsCheckbox2Checked] = useState(false);
    const [isCheckbox3Checked, setIsCheckbox3Checked] = useState(true);

    const [cloudPayments, setCloudPayments] = useState(null);
    const [swalProps, setSwalProps] = useState({});

    useEffect(() => {
        if (currentUser?.user_info?.email === null) return;

        let cur = Packages?.packages?.filter(item => item.packageId === currentUser?.subscribe?.package_id)[0] || Packages?.packages[0];

        if (cur) {
            getTariffsCalculated().then((r) => {
                setTariffCalculated(r?.result);

                setSelected(cur);
                updateDescription(cur, isCheckbox3Checked);
            }).catch((e) => {
                console.error('Error fetching tariffs:', e);
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, [currentUser]);

    useEffect(() => {
        if (!currentUser) return;

        const cp = new CloudPayments({
            src: 'https://widget.cloudpayments.ru/bundles/cloudpayments.js',
            setIsSubmitting,
            isSubmitting,
            toast,
            navigate,
        });

        setCloudPayments(cp);

        if (currentUser?.user_info?.email) {
            handleCheckbox3Change({ target: { checked: !currentUser?.subscribe?.is_auto_charge } });
            setCurrentPackage(currentUser?.subscribe);
        } else {
            setIsLoading(true); // Если данные пользователя еще загружаются
        }
    }, [toast, currentUser]);

    useEffect(() => {
        if (cloudPayments) {
            if (isSubmitting) {
                cloudPayments.addOverlayToDOM();
            } else {
                cloudPayments.removeOverlayFromDOM();
            }
        }
    }, [isSubmitting, cloudPayments]);

    useEffect(() => {
        if (tariffCalculated === undefined) return;

        let foundedTariff = tariffCalculated?.filter(item => item.packageId === selected?.packageId)?.[0];
        setTariffCalculatedCurrent(foundedTariff);
    }, [tariffCalculated, selected]);

    const formSubmit = async () => {
        if (isSubmitting || !selected) return; // Предотвращение множественных отправок

        setIsSubmitting(true);

        try {
            if (cloudPayments && currentUser?.user_info && isMasterChecked) {
                await cloudPayments.pay({
                    description: tariffDescription,
                    amount: selected.newprice,
                    accountId: currentUser?.user_info?.email,
                    isSubscribe: isCheckbox3Checked,
                    packageId: selected.packageId,
                    initDate: tariffCalculatedCurrent?.newRecurrentDateISO
                });
            }
        } catch (error) {
            toast.error("Ошибка при оплате. Пожалуйста, попробуйте снова.");
            setIsSubmitting(false);
        }
    };

    const handleMasterChange = (e) => {
        const checked = e.target.checked;
        setIsMasterChecked(checked);
        setIsCheckbox1Checked(checked);
        setIsCheckbox2Checked(checked);
    };

    const updateDescription = (tariff, autoChargeChecked) => {
        setTariffDescription(`${autoChargeChecked ? 'Ежемесячная подписка на сервис.' : 'Доступ к сервису на 1 месяц.'} Тариф ${tariff.title} (${tariff.storesCount} ${getPlural(tariff.storesCount, 'магазин', 'магазина', 'магазинов')}).`);
    };

    const handleCheckbox3Change = (e) => {
        const checked = e.target.checked;
        setIsCheckbox3Checked(checked);
        updateDescription(selected, checked); // Передача нового значения чекбокса для корректного обновления описания
    };

    const handlePackageSelect = (packageId) => {
        let tariff = Packages?.packages.find((item) => item.packageId === packageId);
        setSelected(tariff);
        updateDescription(tariff, isCheckbox3Checked); // Используем текущее состояние чекбокса для обновления описания
    };

    const changePackage = (e, packageId) => {
        e.preventDefault();

        return setSwalProps({
            show: true,
            title: `Смена тарифа`,
            text: "Для смены тарифа обратитесь в службу поддержки.",
            icon: "warning",
        });
    };

    return (
        <>
            <Header
                selectedStore={selectedStore}
                selectedDate={selectedDate}
            />

            <Container className="app__container-margin-s">
                <form>
                    <div className='tariffs-title'>
                        <h1 className='tariffs-title-text'>Выберите Свой Тариф</h1>
                        <span className='tariffs-title-description'>Узнайте больше о возможностях Ziffar на главной странице.</span>
                    </div>
                    <div className='tariffs-container form-group'>
                        {currentPackage && Packages?.packages?.map((item) => (
                            <Package
                                key={item.packageId}
                                currentPackage={currentPackage}
                                hooks={{ changePackage, register, getPlural, humanNum }}
                                isChecked={item.isChecked}
                                packageId={item.packageId}
                                specialClass={item.specialClass}
                                iconClass={item.iconClass}
                                title={item.title}
                                description={item.description}
                                oldprice={item.oldprice}
                                newprice={item.newprice}
                                storesCount={item.storesCount}
                                onSelect={handlePackageSelect} // Передача функции выбора
                            />
                        ))}
                    </div>

                    {!Packages || !currentUser || isLoading ? (
                        <Skeleton
                            height="3rem"
                            style={{ marginBottom: "1rem", margin: "0 auto", width: "50%", display: "flex" }}
                            count={3}
                        />
                    ) : (
                        <>
                            <div className='tariffs-footer'>
                                {!currentPackage?.is_auto_charge ? <div className="badge-pay">Гарантия возврата - 7 дней</div> : ''}
                                <h1 className='tariffs-footer-text'>
                                    {currentPackage?.is_auto_charge ? 'Автопродление подключено' : 'Один шаг до успеха'}
                                </h1>
                                <p>
                                    {currentPackage?.is_auto_charge
                                        ? 'За один день до окончания подписки мы спишем сумму тарифа с вашей карты.'
                                        : 'Вы сможете отменить подписку на сервис в любой момент'
                                    }
                                </p>

                                {!currentPackage?.is_auto_charge && (
                                    <>
                                        <div className='tariffs-footer-invoice'>
                                            <span className='tariffs-footer-invoice-description'>
                                                <p>{tariffDescription}</p>
                                            </span>
                                            <h1 className='tariffs-footer-invoice-price'>{humanNum(selected.newprice)} ₽</h1>
                                        </div>

                                        <div className='tariffs-footer-action'>
                                            <div className='tariffs-footer-action-options-container'>
                                                <span className='tariffs-footer-action-options'>
                                                    <label className="checkbox style-c">
                                                        <input
                                                            className='checkbox_hover'
                                                            type="checkbox"
                                                            checked={isMasterChecked}
                                                            onChange={handleMasterChange}
                                                        />
                                                        <span className="checkbox__checkmark"></span>
                                                        <span className="checkbox__body checkbox__body-master">Я соглашаюсь с тем, что:</span>
                                                    </label>
                                                    <label className="checkbox style-c">
                                                        <input type="checkbox" checked={isCheckbox1Checked} disabled />
                                                        <span className="checkbox__checkmark checkbox_i_small checkbox_muted"></span>
                                                        <span className="checkbox__body checkbox__body-small">Принимаю <a href="https://ziffar.ru/assets/docs/tos.pdf" target="_blank">договор оферты</a></span>
                                                    </label>
                                                    <label className="checkbox style-c">
                                                        <input type="checkbox" checked={isCheckbox2Checked} disabled />
                                                        <span className="checkbox__checkmark checkbox_i_small checkbox_muted"></span>
                                                        <span className="checkbox__body checkbox__body-small">Согласен с <a href="https://ziffar.ru/assets/docs/moneyback.pdf" target="_blank">политикой возврата средств</a></span>
                                                    </label>
                                                </span>

                                                {!currentUser?.subscribe?.is_auto_charge && (
                                                    <label className="checkbox style-c checkbox__ap">
                                                        <input
                                                            type="checkbox"
                                                            defaultChecked={true}
                                                            onChange={handleCheckbox3Change}
                                                        />
                                                        <span className="checkbox__checkmark checkbox_i_small"></span>
                                                        <span className="checkbox__body form_normal_text">
                                                            Привязать карту для автопродления
                                                        </span>
                                                    </label>
                                                )}

                                                {isCheckbox3Checked && !currentUser?.subscribe?.is_auto_charge && (
                                                    <label>
                                                        <div className="form_card_text checkbox__body-small">
                                                            {tariffCalculatedCurrent?.newRecurrentDate} мы спишем с вашей карты {humanNum(selected.newprice)} руб.
                                                        </div>
                                                    </label>
                                                )}
                                            </div>

                                            <div className="action-info">
                                                <button
                                                    className="button btn_special_pay"
                                                    type="submit"
                                                    onClick={handleSubmit(formSubmit)}
                                                    disabled={isSubmitting || !isValid || !isMasterChecked || !cloudPayments}
                                                >
                                                    <FiShoppingCart className='button-icon' /> &nbsp;{isSubmitting ? 'Загружаем оплату...' : 'Перейти к оплате'}
                                                </button>

                                                <div className='payments-types'></div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </>
                    )}

                </form>
            </Container>
            <SweetAlert2 {...swalProps} />
        </>
    );
};

export default Tariffs;

import { NavLink } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Header from '../Header/Header';
import Container from '../Container/Container';
import "../Icon/Icon.css";
import "./NotFound.css";

const NotFound = ({ isLoggedIn, currentUser, selectedStore }) => {
    return (
        (
            <>
                <Header isLoggedIn={isLoggedIn} currentUser={currentUser} selectedStore={selectedStore} />
                <div className="app__container app__container-margin">
                    <Container>
                        <div className="stores_not-found">
                            <div className="not_found"></div>
                            <h1 className="not_found_title">Ничего не найдено</h1>
                            <NavLink to="/" >
                                <button className="button button__muted">На главную</button>
                            </NavLink>
                        </div>
                    </Container>
                </div>
            </>
        )
    );
};

export default NotFound;
import TableRow from "../TableRow/TableRow.jsx";
import React, { useEffect, useState } from "react";
import '../Items.css';

const Table = ({ children, toast, items, itemsSummary, setCostPrice, selectedStore }) => {
    let itemsArray;
    const [isAllStores, setIsAllStores] = useState();
    let { store_type } = itemsSummary;

    useEffect(() => {
        if (selectedStore.value === 0) {
            setIsAllStores(true);
        } else {
            setIsAllStores(false);
        }
    }, [selectedStore]);

    function handleChange(data) {
        const id = toast.loading("Применяем изменения..");

        return setCostPrice(data).then(async (data) => {
            toast.update(id, {
                render: data.message,
                autoClose: 5000, type: "success", icon: "🚀",
                isLoading: false, hideProgressBar: false, closeOnClick: true,
                pauseOnHover: true, draggable: true
            });
            return Promise.resolve();
        }).catch((err) => {
            toast.update(id, {
                render: err.message,
                autoClose: 5000, type: "error", icon: "🤯",
                isLoading: false, hideProgressBar: false, closeOnClick: true,
                pauseOnHover: true, draggable: true
            });
            return Promise.reject();
        })
    }

    if (items && items.length > 0) {
        itemsArray = items.map((item, i) => {
            return (
                <TableRow item={item} store_id={selectedStore.value} store_type={store_type} isAllStores={isAllStores} handleChange={handleChange} />
            );
        });
    }

    return (
        <>
            <table className="table__items">
                <tr>
                    <th>Артикул Продавца</th>
                    <th>Артикул {store_type === 0 ? 'WB' : store_type == 1 ? 'Ozon' : 'Я.Маркет'}</th>
                    <th>Название</th>
                    {store_type === 0 ? <th>Цвет</th> : ''}
                    <th className="cost-th">Себестоимость</th>
                    {store_type === 0 ? <th>Объем в литрах</th> : ''}
                    {store_type !== 2 ? <th>Последнее изменение</th> : ''}

                </tr>
                {itemsArray}
            </table>

            {children}
        </>
    );
};

export default Table;
import React, { useState, useEffect, useRef } from "react";
import Highcharts from 'highcharts';
import HighchartsMap from 'highcharts/modules/map';
import HighchartsReact from 'highcharts-react-official';
import mapData from '../russia.geo.json';

HighchartsMap(Highcharts);

const HighMap = ({ handleGetLastetOrders, toast, height, is_short }) => {
    const chartRef = useRef(null);
    const tsServerRef = useRef(0); // Реф для хранения актуального tsServer

    const [orders, setOrders] = useState([]);
    const [points, setPoints] = useState([]);
    const [displayedPoints, setDisplayedPoints] = useState(new Set());
    const [isPointDisplaying, setIsPointDisplaying] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [tooltip, setTooltip] = useState(null);
    const [isManualTooltip, setIsManualTooltip] = useState(false);

    const updateOrders = (store_id, ts) => {
        console.log('fetch ', { store_id, ts });
        handleGetLastetOrders({ store_id, ts }).then(async function (response) {
            tsServerRef.current = response?.result?.s_ts; // Обновляем значение рефа

            if (response?.result?.orders_count > 0) {
                response?.result?.orders.forEach(order => {
                    let r = {
                        id: order?.order_id,
                        name: order?.order_region,
                        lat: order?.order_geo_lat,
                        lon: order?.order_geo_lon,
                        sum: order?.order_price_sum,
                        ts: order?.order_date,
                        items: order?.order_items
                    }

                    setOrders((prevOrders) => {
                        const isDuplicate = prevOrders.some(existingOrder => existingOrder.id === r.id);
                        if (!isDuplicate) {
                            return [...prevOrders, r];
                        }
                        return prevOrders;
                    });
                });
            }
        }).catch(err => {
            if (err.statusCode !== 404 && err.statusCode !== 403) {
                toast.error(err.message);
            }
        });
    };

    const createTooltip = (point) => {
        let tooltipContent;
        if (is_short) {
            let x = point.x + 7;
            let y = point.y + 25;
            tooltipContent = (
                <div className='high-tooltip-small' style={{ left: `${x}px`, top: `${y}px`, zIndex: 1000 }}>
                    <span className='o-element o-items'>+{point.opts.sum} руб.</span>
                </div>
            );
        } else {
            tooltipContent = (
                <div className='high-tooltip' style={{ left: `${point.x}px`, top: `${point.y}px`, zIndex: 1000 }}>
                    <div className='o-flex'>
                        <span className='o-element o-title'>+{point.opts.sum} руб.</span>
                        <span className='o-element o-ts'>{point.opts.ts}</span>
                    </div>
                    <span className='o-element o-items'>{point.opts.items}</span>
                </div>
            );
        }

        setTooltip(tooltipContent);
    };

    const removeTooltip = () => {
        setTooltip(null);
    };

    useEffect(() => {
        updateOrders(0, tsServerRef.current);

        const intervalId = setInterval(() => {
            updateOrders(0, tsServerRef.current); // Используем реф для актуального tsServer
        }, 15 * 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        if (points.length === 0) return;

        // Сразу создаем тултип для первой точки
        if (!displayedPoints.has(points[0].opts.id)) {
            createTooltip(points[0]);
            setDisplayedPoints(prevSet => new Set(prevSet).add(points[0].opts.id));
            setCurrentIndex(1); // Устанавливаем индекс на 1 для следующей точки
            setIsPointDisplaying(true);

            setTimeout(() => {
                removeTooltip(); // Удаляем тултип через 4.5 секунды
                setIsPointDisplaying(false);
            }, 4500);
        }

        const intervalId = setInterval(() => {
            if (currentIndex < points.length) {
                const point = points[currentIndex];
                const pointId = point.opts.id;

                if (!displayedPoints.has(pointId)) {
                    console.log('Current new point:', point);
                    createTooltip(point);

                    setDisplayedPoints(prevSet => new Set(prevSet).add(pointId));
                    setCurrentIndex(prevIndex => prevIndex + 1);
                    setIsPointDisplaying(true);

                    setTimeout(() => {
                        removeTooltip();
                        setIsPointDisplaying(false);
                    }, 4500);
                }
            } else {
                clearInterval(intervalId);
            }
        }, 5000);

        return () => clearInterval(intervalId);
    }, [points, currentIndex, displayedPoints]);

    const options = {
        title: {
            text: null
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                states: {
                    inactive: {
                        opacity: 1
                    }
                },
                point: {
                    events: {
                        mouseOver: function () {
                            const chart = this.series.chart;
                            const chartContainer = chart.container.getBoundingClientRect();

                            const x = chartContainer.left + this.plotX + window.scrollX;
                            const y = chartContainer.top + this.plotY + window.scrollY - 70;

                            const point = {
                                opts: this.options,
                                x,
                                y
                            };
                            setIsManualTooltip(true);
                            createTooltip(point);
                        },
                        mouseOut: function () {
                            setIsManualTooltip(false);
                            removeTooltip();
                        }
                    }
                }
            }
        },
        chart: {
            map: mapData,
            type: 'map',
            events: {
                render() {
                    const chart = this;
                    const tooltipDivs = chart.container.querySelectorAll('.high-tooltip');
                    tooltipDivs.forEach(div => div.remove());

                    const createTooltip = () => {
                        // Проверка активности вкладки
                        if (document.visibilityState !== 'visible') {
                            return;
                        }

                        if (!chart?.series?.[1]?.data) {
                            console.log('return')

                            return;
                        }

                        chart?.series[1]?.data.forEach(point => {
                            if (point && point.plotX !== undefined && point.plotY !== undefined) {
                                const chartContainer = chart.container.getBoundingClientRect();

                                const x = chartContainer.left + point.plotX + window.scrollX;
                                const y = chartContainer.top + point.plotY + window.scrollY - 70;

                                const opts = point.options;

                                setPoints((prevPoints) => {
                                    const isDuplicate = prevPoints.some(p => p.x === x && p.y === y);
                                    if (!isDuplicate) {
                                        return [...prevPoints, { opts, x, y }];
                                    }
                                    return prevPoints;
                                });
                            }
                        });
                    };

                    // Создаем наблюдатель для отслеживания видимости
                    const observer = new IntersectionObserver((entries) => {
                        entries.forEach(entry => {
                            if (entry.isIntersecting && document.visibilityState === 'visible') {
                                createTooltip();
                                // Отключаем наблюдатель, если больше не нужно отслеживать
                                observer.disconnect();
                            }
                        });
                    });

                    // Запускаем наблюдатель для контейнера с графиком
                    observer.observe(chart.container);

                    // Обработчик для изменения видимости вкладки
                    document.addEventListener('visibilitychange', () => {
                        if (document.visibilityState === 'visible') {
                            createTooltip();
                        }
                    });
                }
            }

        },
        series: [
            {
                name: 'Basemap',
                mapData: mapData,
                borderColor: 'rgba(200, 200, 200, 0.9)',
                nullColor: 'rgba(200, 200, 200, 0.35)',
                showInLegend: false,
            },
            {
                type: "mappoint",
                name: "Cities",
                color: Highcharts.getOptions().colors[1],
                data: orders.map(order => ({
                    ...order
                })),
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '12px'
                    }
                }
            },
        ],
        tooltip: {
            enabled: false
        },
    };

    return (
        <>
            {!is_short ? (
                <div class="high-map__title">
                    <div class="circle"></div>
                    <span class="live-text">Live</span>
                </div>
            ) : null}

            <HighchartsReact
                containerProps={{ style: { height } }}
                highcharts={Highcharts}
                constructorType={'mapChart'}
                options={options}
                ref={chartRef}
            />
            {tooltip}
        </>
    );
};

export default HighMap;

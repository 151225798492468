import Header from '../Header/Header';
import './Orders.css';
import '../Table/Table.css';
import '../Pagination/Pagination.css';
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Table from "./Table/Table";
import ReactPaginate from "react-paginate";
import Container from '../Container/Container';
import { getPlural, humanNum } from '../../utils/plural';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import NoOrders from '../SpecialPage/NoOrders';
import StyledSelect from '../Select/Select';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import AlertService from '../AlertService/AlertService.jsx';

const Orders = ({ currentUser, getUserOrders, selectedStore, setSelectedStore, isLoading, setIsLoading, selectedDate }) => {
    const navigate = useNavigate()
    let { store_id, page } = useParams();

    const [orders, setOrders] = React.useState([]);
    const [ordersSummary, setOrdersSummary] = React.useState({
        "orders_count_on_page": 0,
        "orders_count_total": 0,
        "orders_sum_on_page": 0,
        "orders_sum_total": 0
    });

    const [currentPage, setCurrentPage] = useState(page);
    const [totalCount, setTotalCount] = useState(0);
    const [limitOnPage, setLimitOnPage] = useState(0);
    const [isNoOrders, setIsNoOrders] = useState(true);
    const [isOnLoad, setIsOnLoad] = useState(true);

    const handlePageClick = (event) => {
        let currentPage = parseInt(event.selected + 1);

        setCurrentPage(currentPage);
        setIsLoading(true);
        navigate(`/orders/${store_id}/page/${currentPage}`)
    };

    useEffect(() => {
        if (currentUser.user_info.email != null) {
            setIsLoading(true);
            getUserOrders({ store_id, page_num: (currentPage - 1) }).then(r => {
                setOrders(r.result);
                setOrdersSummary(r.summary);
                setTotalCount(r.summary.orders_count_total);
                setLimitOnPage(r.summary.limit);
                window.scrollTo({ top: 0 });
                setIsNoOrders(false);
                setIsOnLoad(false);
                setIsLoading(false);
            }).catch((e, r) => {
                setIsNoOrders(true);
                setIsOnLoad(false);
                setIsLoading(false);
                setOrdersSummary({
                    "orders_count_on_page": 0,
                    "orders_count_total": 0,
                    "orders_sum_on_page": 0,
                    "orders_sum_total": 0
                });
            })
        }
    }, [currentUser, store_id, currentPage, page]);

    return (
        <>
            <Header
                selectedStore={selectedStore}
                selectedDate={selectedDate}
            />
            <div className="app__container app__container-margin">
                <AlertService
                    currentUser={currentUser}
                />
                <div className='container-header'>
                    <div className='container-title'>
                        {isLoading ?
                            <SkeletonTheme baseColor="#e0e0e0" highlightColor="#f1f1f1">
                                <Skeleton height="38px" width={'420px'} />
                            </SkeletonTheme>
                            :
                            <>
                                <h1>Всего {totalCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {getPlural(totalCount, 'заказ', 'заказа', 'заказов')} <span className="app_span_xs">на сумму {humanNum(ordersSummary.orders_sum_total)} руб.</span></h1>
                            </>
                        }
                    </div>
                    <div className='container-options'>
                        <StyledSelect
                            userStores={currentUser.stores}
                            selectedStore={selectedStore}
                            setSelectedStore={setSelectedStore}
                        />
                    </div>
                </div>
                {(!isOnLoad && !isLoading && (isNoOrders || totalCount === 0))
                    ?
                    <NoOrders />
                    : <>
                        <Container className={isLoading ? '' : 'scrollable no-pad'}>
                            {isLoading ?
                                <Skeleton height="2rem" style={{ 'margin-bottom': "1rem" }} count={10} />
                                :
                                <Table
                                    orders={orders}
                                    ordersSummary={ordersSummary}
                                    selectedStore={selectedStore}
                                    getPlural={getPlural}
                                    humanNum={humanNum}
                                />
                            }
                            {!isLoading && (Math.ceil(totalCount / limitOnPage) > 1) &&
                                <ReactPaginate
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    pageClassName="page-item"
                                    onPageChange={handlePageClick}
                                    pageCount={(Math.ceil(totalCount / limitOnPage))}
                                    previousClassName={"item previous"}
                                    previousLabel={<MdKeyboardArrowLeft />}
                                    nextClassName={"item next"}
                                    nextLabel={<MdKeyboardArrowRight />}
                                    forcePage={parseInt(currentPage - 1)}
                                />
                            }
                        </Container>
                    </>}
            </div>
        </>
    );
};

export default Orders;
import Auth from '../Auth';

const Login = ({ handleForm, setIsLoggedIn, isLoggedIn }) => {
    return (
        <Auth
            isLogin={true}
            handleForm={handleForm}
            setIsLoggedIn={setIsLoggedIn}
            isLoggedIn={isLoggedIn}
        />
    );
};

export default Login;
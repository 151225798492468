import React, { useEffect, useState } from "react";

const TransactionList = ({ TransactionList }) => {
    return (
        <>
            <table>
                <tr>
                    <th>Номер транзакции</th>
                    <th>Дата</th>
                    <th>Сумма</th>
                    <th>Валюта</th>
                    <th>Способ оплаты</th>
                    <th>Назначение платежа</th>
                </tr>

                {TransactionList?.result?.map((item) => (
                    <tr>
                        <td>{item?.TransactionId}</td>
                        <td>{item?.Date}</td>
                        <td>{item?.Amount}</td>
                        <td>{item?.Currency}</td>
                        <td>{item?.CardType} {item?.CardLastFour}</td>
                        <td>{item?.Description}</td>
                    </tr>
                ))
                }
            </table>
        </>
    );

};

export default TransactionList;
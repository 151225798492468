import Container from '../Container/Container';
import "../Icon/Icon.css";
import "./SpecialPage.css";

const NoItems = () => {
    return (
        <Container className="special_page">
            <div className="stores_not-found no_items_container">
                <div className="no_items"></div>
                <h1>Товары не найдены</h1>
                <span>Возможно, данные с магазином пока что не синхронизированы</span>
            </div>
        </Container>
    );
};

export default NoItems;
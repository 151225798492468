import { useParams } from "react-router-dom";

import Auth from '../Auth';

const ResetPassword = ({ handleForm, setIsLoggedIn, isLoggedIn }) => {
    const { uuid } = useParams();

    return (
        <Auth
            isLogin={false}
            isReset={true}
            uuid={uuid}
            handleForm={handleForm}
            setIsLoggedIn={setIsLoggedIn}
            isLoggedIn={isLoggedIn}
        />
    );
};

export default ResetPassword;
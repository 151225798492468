import "./HorizontalBar.css";

const HorizontalBar = ({ data }) => {
    let result;

    // Проверяем, что data и data.labels существуют и содержат элементы
    if (data?.labels?.length > 0 && data?.datasets?.length > 0) {
        result = data.labels.map((item, i) => {
            const value = data.datasets[i] ?? 0;

            return (
                <div
                    key={`bar-${i}`} // Добавляем ключ для элементов в списке
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: `${value}%` }}
                >
                    <span className="progress-text">{value.toFixed(0)}%</span>
                    {item}
                </div>
            );
        });
    }

    return <div className="bar">{result}</div>;
};

export default HorizontalBar;

import React, { useState, useEffect } from "react";
import { PiCheckFatFill, PiXBold } from "react-icons/pi";

const TableRow = ({ item, store_type, handleChange, store_id }) => {
    let [isConfirmAvailable, setIsConfirmAvailable] = useState(false);
    let [costBase, setCostBase] = useState(0);
    let [cost, setCost] = useState(0);

    useEffect(() => {
        setCost(item.selfprice)
        setCostBase(item.selfprice)
    }, [item]);

    function handleCostClick() {
        if (Number(costBase) !== Number(cost)) {
            setIsConfirmAvailable(true);
        } else {
            setIsConfirmAvailable(previousValue => !previousValue);
        }
    }

    function handleAcceptButtonClick() {
        handleChange({ item_id: item.id, price: cost, store_id }).then(() => {
            setIsConfirmAvailable(false);
        }).catch(() => {

        })
    }

    function handleCancelButtonClick() {
        setIsConfirmAvailable(false);
        setCost(costBase)
    }

    function handleCostChange(e) {
        e.preventDefault();
        setCost(e.target.value);
    }

    return (
        <tr>
            <td>{item.seller_article}</td>
            <td>{item.article}</td>
            <td className="item_td_content"><img src={item.picture} alt="img" className="item_img" /> {item.title}</td>
            {store_type === 0 ? <td>{item.color}</td> : ''}
            <td>
                <div className="container-cost">
                    <input
                        className="input-cost"
                        type="text"
                        value={cost}
                        onChange={handleCostChange}
                        onFocus={e => { e.target.select(); handleCostClick() }}
                        onBlur={handleCostClick}
                    />
                    {isConfirmAvailable ?
                        <>
                            <button
                                className="button button-accept"
                                onClick={(e) => { handleAcceptButtonClick() }}
                            >
                                <PiCheckFatFill color={"#fff"} size={17} />
                            </button>
                            <button
                                className="button button-cancel"
                                onClick={(e) => { handleCancelButtonClick() }}
                            >
                                <PiXBold color={"#fff"} size={17} />
                            </button>
                        </>
                        : ''
                    }</div>
            </td>
            {store_type === 0 ? <td>{item.dimensions}</td> : ''}
            {store_type !== 2 ? <td>{item.date_readable}</td> : ''}
        </tr>
    );
};

export default TableRow;
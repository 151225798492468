import { BsCheck } from 'react-icons/bs'; // Import necessary icons

import { IoStar } from "react-icons/io5";
import { IoIosCloud } from "react-icons/io";

import { CgCalculator } from "react-icons/cg";
import { CgExtension } from "react-icons/cg";
import { CgFormatLineHeight } from "react-icons/cg";
import { PiTelegramLogoDuotone } from "react-icons/pi";
import { BiWorld } from "react-icons/bi";
import { ImStatsBars2 } from "react-icons/im";

const Package = ({ hooks, currentPackage, packageId, isChecked, iconClass, title, specialClass, description, oldprice, newprice, storesCount, onSelect }) => {
    let yourPackage = false;
    let isDisabled = false;

    if (currentPackage?.is_active == undefined) return;

    if (currentPackage?.is_active) {
        if (currentPackage.is_auto_chagre) {
            isDisabled = true;
        }

        if (packageId === currentPackage.package_id) {
            isChecked = true;
            yourPackage = (
                <div className="package-action">
                    <p>Ваш тариф.</p>
                    <p>Активен до {currentPackage.expires_at}.</p>
                </div>
            );
        } else {
            isChecked = false;
            yourPackage = (
                <div className="package-action">
                    <button className='button button__muted button__size_xs' onClick={e => hooks.changePackage(e, packageId)}>Сменить тариф</button>
                </div>
            );
        }
    }

    return (
        <>
            <label htmlFor={'radio_p-' + packageId} key={packageId}>
                <div className="form_radio_tariffs">
                    <input
                        id={'radio_p-' + packageId}
                        type="radio"
                        name="radio_p"
                        value={packageId}
                        defaultChecked={isChecked}
                        disabled={isDisabled}
                        onChange={() => onSelect(packageId)} // Добавлен обработчик изменения
                    />
                    <label htmlFor={'radio_p-' + packageId} className="form yid-label">
                        <div className={`package-container ${specialClass ? specialClass : ''}`}>
                            <div className='package-content'>
                                <div className='package-title'>
                                    <h1 className='package-title-text'>{iconClass ? <i className={`package-icon ${iconClass}`}></i> : ''} {title}</h1>
                                    <span className='package-description'>{description}</span>
                                </div>

                                <div className='package-prices'>
                                    <h2 className='package-price-old'>{hooks.humanNum(oldprice)}<span className='package-price-symbol'>₽</span></h2>
                                    <h2 className='package-price-new'>{hooks.humanNum(newprice)}<span className='package-price-symbol'>₽</span></h2>
                                    <span className='package-price-info'>за месяц</span>
                                </div>
                            </div>
                            <div className='package-features'>
                                <div className='package-features-content'>
                                    <span className='package-features-text package-features-title'>
                                        <IoStar className='package-icon-info package-icon-primary' />
                                        Возможности
                                    </span>
                                </div>

                                <div className='package-features-content'>
                                    <span className='package-features-text'>
                                        <CgExtension className='package-icon-info' />
                                        <b>{storesCount}</b>&nbsp;{hooks?.getPlural(storesCount, 'магазин', 'магазина', 'магазинов')}</span>
                                    <BsCheck className="icon-yes" />
                                </div>
                                <div className='package-features-content'>
                                    <span className='package-features-text'>
                                        <CgCalculator className='package-icon-info' />
                                        Юнит экономика
                                    </span>
                                    <BsCheck className="icon-yes" />
                                </div>
                                <div className='package-features-content'>
                                    <span className='package-features-text'>
                                        <ImStatsBars2 className='package-icon-info' />
                                        ABC анализ
                                    </span>
                                    <BsCheck className="icon-yes" />
                                </div>
                                <div className='package-features-content'>
                                    <span className='package-features-text'>
                                        <CgFormatLineHeight className='package-icon-info' />
                                        Лента заказов
                                    </span>
                                    <BsCheck className="icon-yes" />
                                </div>
                                <div className='package-features-content'>
                                    <span className='package-features-text'>
                                        <BiWorld className='package-icon-info' />
                                        LIVE Карта заказов
                                    </span>
                                    <BsCheck className="icon-yes" />
                                </div>
                                <div className='package-features-content'>
                                    <span className='package-features-text'>
                                        <PiTelegramLogoDuotone className='package-icon-info' />
                                        Уведомления в Telegram
                                    </span>
                                    <BsCheck className="icon-yes" />
                                </div>

                                <div className='package-features-content'>
                                    <span className='package-features-text package-features-title'>
                                        <IoIosCloud className='package-icon-info package-icon-primary' />
                                        API интеграции
                                    </span>
                                </div>

                                <div className='package-features-content'>
                                    <span className='package-features-text'>
                                        <div className='store__icon-wb packege-icon-mp'></div>
                                        Wildberrries
                                    </span>
                                    <BsCheck className="icon-yes" />
                                </div>
                                <div className='package-features-content'>
                                    <span className='package-features-text'>
                                        <div className='store__icon-ozon packege-icon-mp'></div>
                                        Ozon
                                    </span>
                                    <BsCheck className="icon-yes" />
                                </div>
                                <div className='package-features-content'>
                                    <span className='package-features-text'>
                                        <div className='store__icon-yam packege-icon-mp'></div>
                                        Яндекс Маркет
                                    </span>
                                    <BsCheck className="icon-yes" />
                                </div>
                            </div>

                            {yourPackage ?
                                yourPackage
                                : ''}
                        </div>
                    </label>
                </div>
            </label>

        </>
    );
};

export default Package;

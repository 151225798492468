import { Navigate } from 'react-router-dom';
import React from 'react';

function ProtectedRoute({ element: Component, ...props }) {
  return props.isLoggedIn ? (
    <Component {...props} />
  ) : (
    <Navigate to="/signin" replace />
  );
}

export default ProtectedRoute;

import React from "react";

const TableRow = ({ item, ...s }) => {
    let delivery_state_style;
    let is_avg = false;

    const store_icons = ({
        "0": <span className="select-wb-muted select-icon"></span>,
        "1": <span className="select-ozon-muted select-icon"></span>,
        "2": <span className="select-yam-muted select-icon"></span>
    })

    if (item.order_status === 'Доставлен') {
        delivery_state_style = 'delivery_state__successful'
    } else if (item.order_status === 'Отменен') {
        delivery_state_style = 'delivery_state__cancel'
    } else if (item.order_status === 'Доставляется') {
        delivery_state_style = 'delivery_state__processing'
    }

    if ((typeof item?.order_economics?.extra?.logistic_is_avg !== 'undefined' &&
        typeof item?.order_economics?.extra?.fee_is_avg !== 'undefined' &&
        item?.order_economics?.extra?.logistic_is_avg &&
        item?.order_economics?.extra?.fee_is_avg) ||
        (item?.order_economics?.order_fee_sum < 1 &&
            item?.order_economics?.order_logistic_sum < 1 && (item.order_status === 'Доставлен'
                || item.order_status === 'Доставляется'))) {
        is_avg = true;
    }

    return (
        <tr className="tr-hover">
            {!s.isAllStores ? '' : <td>{store_icons[item?.zf_store?.store_type]} {item?.zf_store?.store_name}</td>}
            <td>
                <span className="dt-container">
                    {s.isAllStores ?
                        <span className="dt-date" >{item.order_date}</span> :
                        <>
                            <span className="dt-date" >{item.order_date.split(' ')[0]}</span>
                            <span className="dt-time" >{item.order_date.split(' ')[1]}</span>
                        </>
                    }
                </span>
            </td>
            {!s.isAllStores ? <td>
                <span className='delivery_state'>
                    <span className={"delivery_state_i " + delivery_state_style}></span>
                    {item.order_status}</span>
            </td> : ''}
            <td className="items-row">
                <span className="items-list">
                    {
                        item.order_items.length > 1 ?
                            item.order_items.map((e, i) => {
                                return <div className="item">
                                    <li className="item-title">{e.title}</li>
                                    {e.quantity > 1 ? <span className="qty"><b>{e.quantity}</b> шт.</span> : ''}
                                </div>
                            })
                            : <div className="item">
                                <span className="item-title">{item.order_items[0].title}</span>
                                {item.order_items[0].quantity > 1 ? <span className="qty"><b>{item.order_items[0].quantity}</b> шт.</span> : ''}

                            </div>
                    }
                </span>
            </td>
            {!s.isAllStores ?
                <td className='no-pad'>
                    <table className='td_details border-none table_logistic'>
                        <tr>
                            <td>{item?.order_logistic_route?.origin_wh}</td>
                            <td>Отгрузка</td>
                        </tr>
                        <tr>
                            <td>{item?.order_logistic_route?.destination}</td>
                            <td>Доставка</td>
                        </tr>
                    </table>
                </td> : ''}
            <td>{item.order_economics.order_price_sum} руб</td>
            {!s.isAllStores ?
                <>
                    <td className='no-pad'>
                        <table className='td_details border-none table_economy_summary'>
                            <tr>
                                <td>
                                    {!item?.order_economics?.extra?.logistic_is_avg ?
                                        item?.order_economics?.order_logistic_sum :
                                        <>
                                            <span className="td-text-muted">≈{item?.order_economics?.order_logistic_sum}</span>
                                        </>
                                    }

                                    <span className="td-symbol">&#8381;</span>
                                </td>
                                <td>Логистика</td>
                            </tr>
                            <tr>
                                <td>
                                    {!item?.order_economics?.extra?.fee_is_avg ?
                                        item?.order_economics?.order_fee_sum :
                                        <>
                                            <span className="td-text-muted">≈{item?.order_economics?.order_fee_sum}</span>
                                        </>
                                    }

                                    <span className="td-symbol">&#8381;</span></td>
                                <td>Комиссия</td>
                            </tr>
                            <tr>
                                <td>{is_avg ?
                                    <>
                                        <span className="td-text-muted">≈{item?.order_economics?.extra?.gov_tax}</span>
                                    </>
                                    :
                                    item?.order_economics?.extra?.gov_tax
                                }<span className="td-symbol">&#8381;</span></td>
                                <td>Налог</td>
                            </tr>
                        </table>
                    </td>
                    <td className='no-pad'>
                        <table className='td_details border-none table_economy_summary'>
                            {s.store_type === 0 ? <tr className="no-min-w">
                                <td>{item?.order_economics?.extra?.spp_percentage}<span className="td-symbol">%</span></td>
                                <td>СПП</td>
                            </tr> : ''}
                            <tr>
                                <td>{is_avg ?
                                    <>
                                        <span className="td-text-muted">≈{item?.order_economics?.extra?.marginality}</span>
                                    </>
                                    :
                                    item?.order_economics?.extra?.marginality
                                }<span className="td-symbol">%</span></td>
                                <td>Маржст.</td>
                            </tr>
                            <tr>
                                <td>{is_avg ?
                                    <>
                                        <span className="td-text-muted">≈{item?.order_economics?.extra?.profit}</span>
                                    </>
                                    :
                                    item?.order_economics?.extra?.profit
                                }<span className="td-symbol">&#8381;</span></td>
                                <td>Прибыль</td>
                            </tr>
                        </table>
                    </td>
                </> : ''}
        </tr>
    );
};

export default TableRow;
import React from "react";

const TableRow = ({ item, ...s }) => {
    let score_style = {
        'A': 'tr_score_a',
        'B': 'tr_score_B',
        'C': 'tr_score_C',
    };

    function humanNum(x) {
        if (typeof x === 'undefined') return '0';
        return x.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }

    return (
        <tr className={"tr-hover " + score_style[item.abc_score]}>
            <td>{item.item_title}</td>
            <td>{humanNum(item.item_sum)}</td>
            <td>{item.cur_rev}</td>
            <td>{item.cum_rev}</td>
            <td>{item.abc_score}</td>
        </tr>
    );
};

export default TableRow;
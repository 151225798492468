import React, { useState } from 'react';
import { NavLink } from "react-router-dom";

import "./Alert.css";

const Alert = ({ alert }) => {
    const [isVisible, setIsVisible] = useState(true); // Состояние видимости алерта

    // Если alert не определен или не видим, ничего не рендерим
    if (!alert || !isVisible) {
        return null;
    }

    // Деструктурируем свойства alert
    const { type, message, title, isHiddable, hid, isDisplay, buttonText, buttonLink } = alert;

    // Функция для закрытия алерта и записи его состояния в localStorage
    const closeAlert = () => {
        if (hid?.length > 0) {
            localStorage.setItem('hide-alert-' + hid, 1);
        }
        setIsVisible(false); // Скрываем алерт 
    };

    // Проверка, скрыт ли алерт на основании данных из localStorage
    if (isHiddable && hid?.length > 0) {
        let keyId = localStorage.getItem('hide-alert-' + hid);

        if (keyId !== null) {
            return null; // Не отображаем алерт, если он был скрыт ранее
        }
    }

    // Определяем класс для отображения (показать/скрыть)
    const displayClass = isDisplay ? 'show' : 'hide';

    // Создаем JSX для сообщения, включая заголовок и кнопку закрытия
    const displayMessage = (
        <>
            {title && <h3>{title}</h3>}  {/* Если заголовок присутствует, отображаем его */}
            <span>{message}</span>       {/* Отображаем сообщение */}
            {isHiddable && (
                <span onClick={closeAlert} className="alert-close-button">x</span>
            )}
            {buttonText && buttonLink && (
                <div className="alert__button-container">
                    <NavLink to={buttonLink} >
                        <button className="button button_alert">{buttonText}</button>
                    </NavLink>
                </div>
            )}
        </>
    );

    return (
        <div className={`alert alert-${type} alert-${displayClass}`}>
            {displayMessage}
        </div>
    );
};

export default Alert;

// CollapseSection.js
import React, { useState } from 'react';
import { useCollapse } from 'react-collapsed';
import { FaPlus, FaMinus } from "react-icons/fa";

import './Collapse.css'
function CollapseSection({ title, children }) {
    const [isExpanded, setExpanded] = useState(false);
    const { getToggleProps, getCollapseProps } = useCollapse({ isExpanded });

    return (
        <div>
            <div
                {...getToggleProps({ onClick: () => setExpanded(!isExpanded) })}
            >
                <div className='cl-header'>
                    <div className={!isExpanded ? 'cl-btn' : 'cl-btn cl-btn-extended'}>
                        {isExpanded ? <FaMinus /> : <FaPlus />}
                    </div>
                    <h3 className="cl-title">{title}</h3>
                </div>

            </div>

            <div {...getCollapseProps()}>
                <div className='cl-content'>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default CollapseSection;
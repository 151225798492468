import Container from '../Container/Container';
import "../Icon/Icon.css";
import "./SpecialPage.css";

const NoStores = () => {
    return (
        <Container className="special_page">
            <div className="stores_not-found no_items_container">
                <div className="select_options" ></div>
                <h1>Выберите магазин</h1>
                <span>Пожалуйста, выберите магазин, чтобы продолжить.</span>
            </div>
        </Container>
    );
};

export default NoStores;
module.exports = {
  getPlural:
    function getNoun(number, one, two, five) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return five;
      }
      n %= 10;
      if (n === 1) {
        return one;
      }
      if (n >= 2 && n <= 4) {
        return two;
      }
      return five;
    },
  humanNum:
    function humanNum(x) {
      if (typeof x === 'undefined') return '0';

      if (x >= 1e6) {
        return (x / 1e6).toFixed(x % 1e6 === 0 ? 0 : 2) + 'М';
      } else {
        return x.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
    }

};

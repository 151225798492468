import Header from '../Header/Header';
import '../Table/Table.css';
import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import "../Icon/Icon.css";
import "./Abc-analysis.css";

import StyledSelect from '../Select/Select';
import Table from "./Table/Table";
import ReactPaginate from "react-paginate";
import Container from '../Container/Container';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import NoItems from '../SpecialPage/NoItems';
import DatePicker from '../DatePicker/DatePicker';
import AlertService from '../AlertService/AlertService.jsx';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";

const AbcAnalysis = ({ toast, currentUser, handleAbcAnalysis,
    selectedStore, setSelectedStore, isLoading, setIsLoading,
    selectedDate, setSelectedDate }) => {
    const navigate = useNavigate()
    let { store_id, page, date_range } = useParams();

    const [items, setItems] = React.useState([]);
    const [itemsSummary, setItemsSummary] = React.useState({ count: 0 });

    const [currentPage, setCurrentPage] = useState(page);
    const [totalCount, setTotalCount] = useState(0);
    const [limitOnPage, setLimitOnPage] = useState(0);
    const [isOnLoad, setIsOnLoad] = useState(true);

    const handlePageClick = (event) => {
        let currentPage = parseInt(event.selected + 1);

        setCurrentPage(currentPage);
        setIsLoading(true);
        navigate(`/abc-analysis/${store_id}/page/${currentPage}/date/${date_range}`)
    };

    useEffect(() => {
        setIsLoading(true);
        handleAbcAnalysis({ date_range, store_id, page_num: (currentPage - 1) }).then(r => {
            setItems(r.result);
            setItemsSummary(r.summary);
            setTotalCount(r.summary.total_count);
            setLimitOnPage(r.summary.limit);
            setIsLoading(false);
            setIsOnLoad(false)

        }).catch((err) => {
            if (err.statusCode !== 404 && err.statusCode !== 403) {
                toast.error(err.message);
            }
            setItems([]);
            setIsLoading(false);
            setIsOnLoad(false)
        })
    }, [store_id, currentPage, date_range]);

    return (
        <>
            <Header
                selectedStore={selectedStore}
                selectedDate={selectedDate}
            />
            <div className="app__container app__container-margin">
                <AlertService
                    currentUser={currentUser}
                />
                <div className='container-header'>
                    <div className='container-title'>
                        {(isLoading) ?
                            <SkeletonTheme baseColor="#e0e0e0" highlightColor="#f1f1f1">
                                <Skeleton height="38px" width={'420px'} />
                            </SkeletonTheme>
                            :
                            <>
                                <h1>ABC анализ</h1>
                            </>
                        }
                    </div>
                    <div className='container-options'>
                        <DatePicker
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                        />
                        <StyledSelect
                            userStores={currentUser.stores.slice(1)}
                            selectedStore={selectedStore}
                            setSelectedStore={setSelectedStore}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                        />
                    </div>
                </div>


                <Container className={isLoading ? '' : 'no-pad'}>
                    {isLoading ?
                        <Skeleton height="2rem" style={{ 'margin-bottom': "1rem" }} count={10} />
                        :
                        !isOnLoad && !isLoading && (parseInt(store_id) === 0 || items.length === 0) ?
                            <NoItems />
                            :
                            <>
                                <Table
                                    items={items}
                                    itemsSummary={itemsSummary}
                                    selectedStore={selectedStore}
                                />
                            </>
                    }
                    {!isLoading && (Math.ceil(totalCount / limitOnPage) > 1) &&
                        <ReactPaginate
                            containerClassName="pagination"
                            activeClassName="active"
                            pageClassName="page-item"
                            onPageChange={handlePageClick}
                            pageCount={(Math.ceil(totalCount / limitOnPage))}
                            previousClassName={"item previous"}
                            previousLabel={<MdKeyboardArrowLeft />}
                            nextClassName={"item next"}
                            nextLabel={<MdKeyboardArrowRight />}
                            forcePage={parseInt(currentPage - 1)}
                        />

                    }
                </Container>

            </div>
        </>
    );
};

export default AbcAnalysis;
import './InfoTable.css';

import React, { useEffect, useState } from "react";

const InfoTable = ({ labels, tableData, isAbc = false, humanNum }) => {
    const [labelsR, setLabelsR] = useState([]);
    const [dataR, setDataR] = useState([]);

    const score_style = {
        0: 'tr_score_a',
        1: 'tr_score_B',
        2: 'tr_score_C',
    };

    useEffect(() => {
        if (tableData?.length > 0 && labels?.length > 0) {
            setLabelsR(labels.map((item, i) => (
                <th key={`header-${i}`}>{item}</th>
            )));

            setDataR(tableData.map((item, i) => {
                const styled = isAbc ? score_style[i] : '';
                const value = isAbc ? item[1] : humanNum(item[1]);

                return (
                    <tr key={`row-${i}`} className={styled}>
                        <td className='info_td_details'>{item[0]}</td>
                        <td>{value}</td>
                    </tr>
                );
            }));
        }
    }, [labels, tableData, isAbc, humanNum]);

    return (
        <table>
            <thead>
                <tr>
                    {labelsR}
                </tr>
            </thead>
            <tbody>
                {dataR}
            </tbody>
        </table>
    );
};

export default InfoTable;

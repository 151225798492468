import Api from '../../utils/MainApi.js';

const api = new Api();

class App {
  async handleSignIn(data) {
    return api.signIn(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }

  async handleSignUp(data) {
    return api.signUp(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }

  async handleNewUserStore(data) {
    return api.newUserStore(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }

  async handleEditUserStore(data) {
    return api.editUserStore(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }

  async handleDeleteUserStore(data) {
    return api.deleteUserStore(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }

  async handleGetUserOrders(data) {
    return api.getUserOrders(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }

  async handleGetUserItems(data) {
    return api.getUserItems(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }

  async handleSetCostPrice(data) {
    return api.setCostPrice(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }

  async handleGetChartOrders(data) {
    return api.getChartOrders(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }

  async handleGetDashboardExtended(data) {
    return api.getDashboardExtended(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }

  async handleAbcAnalysis(data) {
    return api.getAbcAnalysis(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }

  async handleGetTgToken(data) {
    return api.getTgToken(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }

  async handleGetTgSettings(data) {
    return api.getTgSettings(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }

  async handleSetTgSettings(data) {
    return api.setTgSettings(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }

  async handleGetLastetOrders(data) {
    return api.getLastetOrders(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }

  async handleUserActivation(data) {
    return api.getUserActivation(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }

  async handleGetUserTransactions(data) {
    return api.getUserTransactions(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }

  async handleGetTariffsCalculated(data) {
    return api.getTariffsCalculated(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }

  async handleResetPasswordRequest(data) {
    return api.getResetPasswordRequest(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }

  async handleResetPassword(data) {
    return api.getResetPassword(data).then(async (data) => Promise.resolve(data)).catch((err) => Promise.reject(err));
  }
}

export default App;

import { useEffect } from 'react';
import './Footer.css';

const Footer = ({ path }) => {
    useEffect(() => {
        if (path === '/signin' || path === '/signup' || path.startsWith('/reset-password')) {
            document.body.style.margin = '0';
        } else {
            document.body.style.margin = '';
        }
    }, [path]);

    if (path === '/signin' || path === '/signup' || path.startsWith('/reset-password')) return null;

    return (
        <div className="app__footer">
            <div className="app_footer_container">
                <span className="app_footer_logo">© Ziffar 2024. Все права защищены.</span>
                <span className="app_footer_info">Поддержка: <a href="mailto:support@ziffar.ru" target="_blank" rel="noreferrer">support@ziffar.ru</a></span>
            </div>
        </div>
    );
}

export default Footer;

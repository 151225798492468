import Select from 'react-select';

const options = [
    { value: '7d', label: '7 дней' },
    { value: '14d', label: '14 дней' },
    { value: '1m', label: '1 месяц' },
    { value: '3m', label: '3 месяца' },
    { value: '6m', label: '6 месяцев' },
    { value: '1y', label: '1 год' },
];

const CustomStyle = {
    option: (base) => ({
        ...base,
        'width': "140px"
    }),
    control: styles => ({ ...styles, 'width': '140px' })
}

export default function DatePicker({ selectedDate, setSelectedDate }) {
    return (
        <div className="App">
            <Select
                defaultValue={selectedDate}
                value={selectedDate}
                onChange={setSelectedDate}
                options={options}
                styles={CustomStyle}
                placeholder='Выберите период'
            />
        </div>
    );
}
import "./Select.css";

import { useEffect, useState } from "react";
import Select, { components } from 'react-select'
import { useParams } from "react-router-dom";

const { Option } = components;

const CustomStyle = {
  option: (base) => ({
    ...base,
    padding: "8px 10px",
  }),
  container: provided => ({
    ...provided,
    'min-width': 150
  }),
  control: styles => ({ ...styles, 'border-style': 'solid' })
}

export default function Selection({ userStores, ...s }) {
  const store_icons = ({
    "0": <span className="select-wb select-icon"></span>,
    "1": <span className="select-ozon select-icon"></span>,
    "2": <span className="select-yam select-icon"></span>,
  })

  const CustomSelectOption = props => (
    <Option {...props}>
      <div className="select-flex">{store_icons[props.data.store_type]}  {props.data.label} </div>
    </Option>
  )

  const CustomSelectValue = props => (
    <div className="select-flex select-grid">{store_icons[props.data.store_type]}  {props.data.label}</div>
  )


  return (
    <Select
      value={s.selectedStore}
      defaultValue={s.selectedStore}
      onChange={s.setSelectedStore}
      options={userStores}
      placeholder='Выберите магазин'
      components={{ Option: CustomSelectOption, SingleValue: CustomSelectValue }}
      isSearchable={false}
      styles={CustomStyle}
      className="select-wrapper"
      classNamePrefix="select"
    />
  );
}

import TableRow from "../TableRow/TableRow.jsx";
import React, { useEffect, useState } from "react";

const Table = ({ children, orders, ordersSummary, selectedStore, getPlural, humanNum }) => {
    const [ordersArray, setOrdersArray] = useState();
    const [isAllStores, setIsAllStores] = useState();
    const { store_type } = ordersSummary;

    useEffect(() => {
        if (store_type === 10) {
            setIsAllStores(true);
        } else {
            setIsAllStores(false);
        }
    }, [selectedStore]);

    useEffect(() => {
        if (orders?.length > 0 && typeof isAllStores !== 'undefined') {
            setOrdersArray(orders.map((item, i) => {
                return (
                    <TableRow item={item} isAllStores={isAllStores} store_type={store_type} />
                );
            }));
        }
    }, [orders, isAllStores, store_type]);

    return (
        <>
            <table>
                <tr>
                    <td className="border-none" colSpan={isAllStores ? 6 : 5}>Показано {ordersSummary.orders_count_on_page} {getPlural(ordersSummary.orders_count_on_page, 'заказ', 'заказа', 'заказов')} на сумму {humanNum(ordersSummary.orders_sum_on_page)} руб.</td>
                    {!isAllStores ? <td colSpan="2">Экономика</td> : ''}
                </tr>
                <tr>
                    {isAllStores ? <th>Магазин</th> : ''}
                    <th>Дата</th>
                    {!isAllStores ? <th>Статус</th> : ''}
                    <th>Товар</th>
                    {!isAllStores ? <th>Маршрут</th> : ''}
                    <th>Сумма</th>
                    {!isAllStores ? <th>Переменные расходы</th> : ''}
                    {!isAllStores ? <th>Сводка</th> : ''}
                </tr>

                {ordersArray}
            </table>
            {children}
        </>
    );

};

export default Table;
class CloudPayments {
    constructor({ src, isSubmitting, setIsSubmitting, toast, navigate }) {
        this.src = src;
        this.setIsSubmitting = setIsSubmitting;
        this.isSubmitting = isSubmitting
        this.toast = toast;
        this.navigate = navigate;

        this.loadScript();
    }

    loadScript() {
        if (typeof window !== 'undefined') {
            const existingScript = document.querySelector(`script[src="${this.src}"]`);
            if (!existingScript) {
                const script = document.createElement('script');
                script.src = this.src;
                script.async = true;
                document.head.appendChild(script);

                script.onload = () => {
                    console.log('CloudPayments script loaded');
                };

                script.onerror = () => {
                    console.error('Failed to load CloudPayments script');
                };
            }
        }
    }

    // Функция для добавления оверлея в DOM
    addOverlayToDOM() {
        if (typeof window !== 'undefined') {
            const existingOverlay = document.getElementById('custom-overlay');
            if (!existingOverlay) {
                const overlay = document.createElement('div');
                overlay.id = 'custom-overlay';
                overlay.style.position = 'fixed';
                overlay.style.top = '0';
                overlay.style.left = '0';
                overlay.style.width = '100%';
                overlay.style.height = '100%';
                overlay.style.backgroundColor = 'rgba(11, 30, 70, 0.74)';
                overlay.style.zIndex = '20';
                overlay.style.display = 'flex';

                document.body.appendChild(overlay);
            }
        }
    };

    // Функция для удаления оверлея из DOM
    removeOverlayFromDOM() {
        const overlay = document.getElementById('custom-overlay');
        if (overlay) {
            document.body.removeChild(overlay);
        }
    };

    async pay({ description, amount, accountId, isSubscribe, packageId, initDate }) {
        if (window.cp) {
            const widget = new window.cp.CloudPayments();
            const self = this;

            const commonOptions = {
                publicId: 'pk_4afc8a2a503e0aebbce208959e5da', // ID из личного кабинета
                description: description, // Назначение платежа
                amount: amount, // Сумма
                currency: 'RUB', // Валюта
                accountId: accountId, // Идентификатор плательщика
                email: accountId, // E-mail для отправки сообщения
                skin: "classic",
                autoClose: 3,
            };

            // Вспомогательная функция для отображения уведомлений
            function showToast(type, message) {
                const options = {
                    icon: type === 'success' ? "❤️" : "😥",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                };

                if (type === 'success') {
                    self.toast.success(message, options);
                } else if (type === 'error') {
                    self.toast.error(message, options);
                }
            }

            if (isSubscribe) {
                const receipt = {
                    Items: [
                        {
                            label: description, // Наименование товара
                            price: amount, // Цена
                            quantity: 1.00, // Количество
                            amount: amount, // Сумма
                            vat: 0, // Ставка НДС
                        }
                    ],
                    email: accountId, // E-mail покупателя
                    isBso: false, // Чек является бланком строгой отчетности
                    amounts: {
                        electronic: 0.00, // Сумма оплаты электронными деньгами
                        advancePayment: amount, // Сумма предоплаты
                        credit: 0.00, // Сумма постоплаты
                        provision: 0.00 // Сумма оплаты встречным предоставлением
                    }
                };

                const data = {
                    packageId: packageId,
                    CloudPayments: {
                        CustomerReceipt: receipt, // Чек для первого платежа
                        // calc startDate and check validity on server
                        recurrent: {
                            interval: 'Month',
                            period: 1,
                            startDate: initDate,
                            customerReceipt: receipt // Чек для регулярных платежей
                        }
                    }
                };

                widget.charge({
                    ...commonOptions,
                    data
                },
                    function (options) { // Успех
                        console.log('Платеж успешен:', options);
                        showToast('success', "Подписка оформлена.");
                        self.removeOverlayFromDOM();
                        self.navigate('/myStores');
                        self.setIsSubmitting(false);
                    },
                    function (reason, options) { // Ошибка
                        console.error('Ошибка платежа:', reason, options);
                        showToast('error', `Ошибка платежа. ${reason ? reason : 'Попробуйте еще раз.'}`);
                        self.setIsSubmitting(false);
                    });

            } else {
                widget.pay('charge', {
                    ...commonOptions,
                    data: { packageId: packageId }
                }, {
                    onSuccess: function (options) {
                        console.log('Платеж успешен:', options);
                        showToast('success', "Платеж зачислен.");
                        self.removeOverlayFromDOM();
                        self.navigate('/myStores');
                        self.setIsSubmitting(false);
                    },
                    onFail: function (reason, options) {
                        console.error('Ошибка платежа:', reason, options);
                        showToast('error', `Ошибка платежа. ${reason ? reason : 'Попробуйте еще раз.'}`);
                        self.setIsSubmitting(false);
                    },
                    onComplete: function (paymentResult, options) {
                        console.log('Платеж завершен:', paymentResult, options);
                        self.setIsSubmitting(false);
                    },
                });
            }
        } else {
            console.error('Скрипт CloudPayments не загружен');
        }
    }

}

export default CloudPayments;

import Header from '../Header/Header';
import '../Table/Table.css';
import React, { useEffect, useState } from "react";
import Table from "./Table/Table";
import ReactPaginate from "react-paginate";
import { getPlural } from '../../utils/plural';
import Container from '../Container/Container';
import NoItems from '../SpecialPage/NoItems';
import NoStores from '../SpecialPage/NoStores';
import AlertService from '../AlertService/AlertService.jsx';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import { useNavigate, useParams } from "react-router-dom";
import "../Icon/Icon.css";
import StyledSelect from '../Select/Select';

import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";


const Items = ({ toast, currentUser, getUserItems, setCostPrice, selectedStore, setSelectedStore, isLoading, setIsLoading, selectedDate }) => {
    const navigate = useNavigate()
    let { store_id, page } = useParams();

    const [items, setItems] = React.useState([]);
    const [itemsSummary, setItemsSummary] = React.useState({ count: 0, sum: 0, limit: 100 });

    const [currentPage, setCurrentPage] = useState(page);
    const [totalCount, setTotalCount] = useState(0);
    const [limitOnPage, setLimitOnPage] = useState(0);
    const [isOnLoad, setIsOnLoad] = useState(true);

    const handlePageClick = (event) => {
        let currentPage = parseInt(event.selected + 1);

        setCurrentPage(currentPage);
        setIsLoading(true);
        navigate(`/items/${store_id}/page/${currentPage}`)
    };

    useEffect(() => {
        if (currentUser.user_info.email != null) {
            setIsLoading(true);
            if (parseInt(store_id) !== 0) {
                getUserItems({ store_id, page_num: (currentPage - 1) }).then(r => {
                    setItems(r.result);
                    setItemsSummary(r.summary);
                    setTotalCount(r.summary.count);
                    setLimitOnPage(r.summary.limit);
                    window.scrollTo({ top: 0 });
                    setIsLoading(false);
                    setIsOnLoad(false);
                }).catch((err) => {
                    toast.error(err.message);
                    setIsOnLoad(false);
                })
            } else {
                setIsLoading(false);
                setItemsSummary({ count: 0 });
            }
        }
    }, [currentUser, store_id, page]);

    return (
        <>
            <Header
                selectedDate={selectedDate}
                selectedStore={selectedStore}
            />

            <div className="app__container app__container-margin">
                <AlertService
                    currentUser={currentUser}
                />
                <div className='container-header'>
                    <div className='container-title'>
                        {(isLoading) ?
                            <SkeletonTheme baseColor="#e0e0e0" highlightColor="#f1f1f1">
                                <Skeleton height="38px" width={'420px'} />
                            </SkeletonTheme>
                            :
                            <>
                                <h1>Найдено {itemsSummary.count} {getPlural(itemsSummary.count, 'товар', 'товара', 'товаров')}</h1>
                            </>
                        }
                    </div>
                    <div className='container-options'>
                        <StyledSelect
                            userStores={currentUser.stores}
                            selectedStore={selectedStore}
                            setSelectedStore={setSelectedStore}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                        />
                    </div>
                </div>
                {parseInt(store_id) === 0 ?
                    <NoStores />
                    : (!isOnLoad && !isLoading && parseInt(itemsSummary.count) === 0)
                        ?
                        <NoItems />
                        : <>
                            <Container className={isLoading ? '' : 'scrollable no-pad'}>
                                {isLoading ?
                                    <Skeleton height="2rem" style={{ 'margin-bottom': "1rem" }} count={10} />
                                    :
                                    <Table
                                        toast={toast}
                                        items={items}
                                        itemsSummary={itemsSummary}
                                        selectedStore={selectedStore}
                                        setCostPrice={setCostPrice}
                                    />
                                }
                                {!isLoading && (Math.ceil(totalCount / limitOnPage) > 1) &&
                                    <ReactPaginate
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        pageClassName="page-item"
                                        onPageChange={handlePageClick}
                                        pageCount={(Math.ceil(totalCount / limitOnPage))}
                                        previousClassName={"item previous"}
                                        previousLabel={<MdKeyboardArrowLeft />}
                                        nextClassName={"item next"}
                                        nextLabel={<MdKeyboardArrowRight />}
                                        forcePage={parseInt(currentPage - 1)}
                                    />
                                }
                            </Container>
                        </>
                }
            </div>
        </>
    );
};

export default Items;
import React, { useEffect, useRef } from 'react';
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import "./dashboard.css";

function Dashboards({ chartData, selectedStore }) {
    const chartRef = useRef(null);
    const baseStyle = {
        type: selectedStore.value === 0 ? 'bar' : 'line',
        smooth: true,
        symbol: 'none',
        seriesLayoutBy: 'row',
        stack: selectedStore.value === 0 ? 'total' : false,
        emphasis: {
            focus: 'series',
            scale: false
        },
        silent: true,
        color: selectedStore.value === 0 ? ['#FFC253', '#FF7E9A', '#5136EA', '#369CEA', '#36EA65'] : ['#8aadff', '#ad87ed', '#FD9D33', '#ECD31F', '#1FECA2', '#FFC253', '#FF7E9A']
    };

    function convertNumber(num) {
        if (num >= 1_000_000_000) {
            return (num / 1_000_000_000).toFixed(0) + 'B';
        } else if (num >= 1_000_000) {
            return (num / 1_000_000).toFixed(0) + 'M';
        } else if (num >= 1_000) {
            return (num / 1_000).toFixed(0) + 'K';
        } else {
            return num.toString();
        }
    }

    function generateSeries(data, style) {
        let series = [];
        for (let i = 0; i < data.length; i++) {
            let lineStyle = {};
            if (i === 0 && selectedStore.value !== 0) {
                lineStyle = {
                    lineStyle: {
                        width: 1,
                        type: 'solid',
                    },
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: 'rgba(161, 190, 255)' },
                            { offset: 1, color: 'rgba(105, 150, 255, 0.1)' }
                        ])
                    },
                    stack: i,
                    animation: false
                }
            } else if (i === 1 && selectedStore.value !== 0) {
                lineStyle = {
                    lineStyle: {
                        width: 0,
                        type: 'solid',
                    },
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                            { offset: 0, color: 'rgba(161, 190, 255)' },
                            { offset: 1, color: 'rgba(105, 150, 255, 0.2)' }
                        ])
                    },
                    itemStyle: {
                        color: style.color[i]
                    },
                    stack: i,
                    animation: false
                }
            } else {
                lineStyle = {
                    lineStyle: {
                        width: selectedStore.value !== 0 ? 2.5 : 3,
                        type: selectedStore.value !== 0 ? 'dashed' : 'solid',
                        opacity: selectedStore.value !== 0 ? 0.5 : 0.8,
                        color: style.color[i]
                    },
                    itemStyle: {
                        color: style.color[i],
                        borderType: 'solid',
                    },
                    animation: false
                }
            }
            series.push({
                ...lineStyle,
                ...style,
            })
        }
        return series
    }

    const option = {
        tooltip: {
            trigger: 'axis',
            formatter: (params) => {
                let tooltipContent = `<span class="tooltip-title">Дата: ${params[0].axisValueLabel}</span>`;
                let totalSum = 0;
                let expenseMarkers = ["Комиссия", "Логистика", "Налог", "Себестоимость"];
                let hasExpenses = false;

                for (let i = 0; i < params.length; i++) {
                    let value;
                    if (params[i].seriesName === "Заказы") {
                        value = params[i].value[1] || 0;
                    } else if (params[i].seriesName === "Выкупы") {
                        value = params[i].value[2] || 0;
                    } else if (params[i].seriesName === "Прибыль") {
                        value = params[i].value[3] || 0;
                    } else if (params[i].seriesName === "Комиссия") {
                        value = params[i].value[4] || 0;
                    } else if (params[i].seriesName === "Логистика") {
                        value = params[i].value[5] || 0;
                    } else if (params[i].seriesName === "Налог") {
                        value = params[i].value[6] || 0;
                    } else if (params[i].seriesName === "Себестоимость") {
                        value = params[i].value[7] || 0;
                    } else {
                        value = params[i].value[i + 1] || 0;
                    }

                    // Проверяем, если маркер текущего параметра является расходом
                    if (expenseMarkers.includes(params[i].seriesName)) {
                        if (!hasExpenses && selectedStore.value !== 0) {
                            tooltipContent += '<br/> <span class="tooltip-title tooltip-fees">Расходы:</span>';
                            hasExpenses = true;
                        }
                    }

                    // Добавляем информацию о текущем параметре в содержимое tooltip
                    tooltipContent += `<br/>${params[i].marker} ${params[i].seriesName}: <b>${value}</b> ₽`;
                    totalSum += value;
                }

                // Добавляем строку с общей суммой, если selectedStore имеет нулевое значение
                if (selectedStore.value === 0) {
                    tooltipContent += `<br><span class="tooltip-title">Заказано на сумму: <b>${totalSum.toFixed(2)}</b> ₽</span>`;
                }

                return `<span class="tooltip-box">${tooltipContent}</span>`;
            }
        },
        legend: {},
        dataset: {
            source: [
                ['product', ...chartData.dates],
                ...chartData.result
            ]
        },
        toolbox: {
            feature: {
                magicType: { show: true, type: ['line', 'bar'] },
                saveAsImage: { show: true }
            }
        },
        grid: {
            left: '0%',
            right: '0%',
            bottom: '0%',
            containLabel: true
        },
        xAxis: [
            {
                type: 'category',
                boundaryGap: false,
            }
        ],
        yAxis: [
            {
                type: 'value',
                min: 0,
                position: 'right',
                axisLabel: {
                    inside: true,
                    margin: 10,
                    fontSize: 12,
                    formatter: val => `${convertNumber(val)}`
                },
            }
        ],
        series: generateSeries(chartData.result, baseStyle)
    };

    useEffect(() => {
        const handleResize = () => {
            if (chartRef.current) {
                chartRef.current.getEchartsInstance().resize();
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <ReactECharts
            ref={chartRef}
            option={option}
            style={{ height: 460 }}
            notMerge={true}
        />
    );
};

export default Dashboards;

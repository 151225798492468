import React, { useEffect } from "react";
import { useParams, NavLink } from "react-router-dom";

import Header from '../Header/Header';
import Container from '../Container/Container';
import EchartDashboard from "../Echarts/dashboards";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import StyledSelect from '../Select/Select';
import DatePicker from '../DatePicker/DatePicker';
import NoData from './NoData';
import HorizontalBar from "../HorizontalBar/HorizontalBar";
import InfoTable from "./InfoTable/InfoTable";
import './Dashboard.css';

import HighMap from "../OrdersMap/HighMap/HighMap.jsx";

import { BsTruck as CiDeliveryTruck } from "react-icons/bs";
import { BsBoxSeam } from "react-icons/bs";
import { IoReturnDownBackOutline as MdOutlineCancel } from "react-icons/io5";

import { getPlural } from '../../utils/plural';
import AlertService from '../AlertService/AlertService.jsx';

const Dashboard = ({
    isLoggedIn, currentUser, selectedStore,
    getChartOrders, setSelectedStore, toast,
    selectedDate, setSelectedDate,
    getDashboardExtended, handleGetLastetOrders,
    setIsUrlMatch, isUrlMatch }) => {
    const [chartData, setChartData] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(true);
    const [isNoData, setIsNoData] = React.useState(false);
    const { store_id, date_range } = useParams();

    const [extendData, setExtendData] = React.useState([]);
    const [isLoadingED, setIsLoadingED] = React.useState(true);
    const [isNoDataED, setIsNoDataEd] = React.useState(false);

    function humanNum(x) {
        if (typeof x === 'undefined' || x == null) return '0';

        if (x >= 1e7) {
            return (x / 1e6).toFixed(2) + 'М';
        } else {
            return x.toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
    }

    useEffect(() => {
        setIsLoading(true);
        setIsLoadingED(true);

        getChartOrders({ store_id, date_range }).then(r => {
            setChartData(r);
            setIsLoading(false);
            setIsNoData(false);

            getDashboardExtended({ store_id, date_range }).then(r => {
                setExtendData(r.result);
                setIsLoadingED(false);
                setIsNoDataEd(false);
            }).catch((err) => {
                setIsLoadingED(false);
                setIsNoDataEd(true);

                if (err.statusCode !== 404 && err.statusCode !== 403) {
                    toast.error(err.message);
                }

            })
        }).catch((err) => {
            setIsNoData(err.message);
            setIsLoading(false);
            setIsLoadingED(false);
            setIsNoDataEd(true);

            if (err.statusCode !== 404 && err.statusCode !== 403) {
                toast.error(err.message);
            }

        })

    }, [store_id, date_range, getChartOrders, toast, getDashboardExtended])

    return (
        <>
            <Header
                selectedStore={selectedStore}
                selectedDate={selectedDate}
            />
            <div className="app__container app__container-margin">
                <AlertService
                    currentUser={currentUser}
                />

                <div className="container-row">
                    <Container className={'w_100'}>
                        <h2>{isLoading ? <SkeletonTheme ><Skeleton height="18px" /></SkeletonTheme>
                            : !isNoData ? humanNum(chartData?.stats?.orders_sum) + ' руб.' : 0 + ' руб.'}</h2>
                        <h3 className="s-text">Все заказы</h3>
                    </Container>
                    <Container className={'w_100'}>
                        <h2>{isLoading ? <SkeletonTheme ><Skeleton height="18px" /></SkeletonTheme>
                            : !isNoData ? humanNum(chartData?.stats?.delivered_sum) + ' руб.' : 0 + ' руб.'}</h2>
                        <h3 className="s-text">Выкупы</h3>
                    </Container>
                    <Container className={'w_100'}>
                        <h2>{isLoading ? <SkeletonTheme ><Skeleton height="18px" /></SkeletonTheme>
                            : !isNoData ? humanNum(chartData?.stats?.fees_sum) + ' руб.' : 0 + ' руб.'}</h2>
                        <h3 className="s-text">Все расходы</h3>
                    </Container>
                    <Container className={'w_100'}>
                        <h2>{isLoading ? <SkeletonTheme ><Skeleton height="18px" /></SkeletonTheme>
                            : !isNoData ? humanNum(chartData?.stats?.profit_sum) + ' руб.' : 0 + ' руб.'}</h2>
                        <h3 className="s-text">Чистая прибыль</h3>
                    </Container>
                </div>
                <div className='container-header'>
                    <div className='container-title'>
                        {isLoading ?
                            <SkeletonTheme baseColor="#e0e0e0" highlightColor="#f1f1f1">
                                <Skeleton height="36px" width={'420px'} />
                            </SkeletonTheme>
                            :
                            <>
                                <h1>Сводная аналитика <span className="app_span_xs"> магазин <b>{selectedStore.label}</b></span></h1>
                            </>
                        }
                    </div>
                    <div className='container-options'>
                        <DatePicker
                            selectedDate={selectedDate}
                            setSelectedDate={setSelectedDate}
                        />
                        <StyledSelect
                            userStores={currentUser.stores}
                            selectedStore={selectedStore}
                            setSelectedStore={setSelectedStore}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            setIsUrlMatch={setIsUrlMatch}
                            isUrlMatch={isUrlMatch}
                        />
                    </div>
                </div>
                <div className="container-row">
                    <Container className={'w_part_s grid_v no_pad'}>
                        <div className="bg_green">
                            <span className="text-revenue">
                                <h2>Выручка</h2>

                                {!isLoading ? <>
                                    <h1>{humanNum(chartData?.stats?.revenue_sum)}₽</h1>
                                </> : <SkeletonTheme>
                                    <Skeleton count={1} height="46px" baseColor="#20a751" highlightColor="#30c12e" style={{ marginTop: "10px" }} />
                                </SkeletonTheme>
                                }
                            </span>

                        </div>

                        <Container className={'info_list'}>
                            <div className="info_item">
                                {!isLoading ? <>
                                    <div className="info_symbol"> </div>
                                    <div className="info_content">
                                        <h3>ROI</h3>
                                    </div>
                                    <div className="info_result">
                                        <h2>{humanNum(chartData?.stats?.roi)}%</h2>
                                    </div>
                                </> : <SkeletonTheme>
                                    <Skeleton count={1} height="40px" containerClassName="sk_small_flex" />
                                </SkeletonTheme>
                                }
                            </div>

                            <div className="info_item">
                                {!isLoadingED ? <>
                                    <div className="info_symbol">
                                        <CiDeliveryTruck className="h-icon" />
                                    </div>
                                    <div className="info_content">
                                        <h3>Доставляется</h3>
                                        <h4>{extendData?.cancelledOrders?.in_deilvery_count || 0}&nbsp;
                                            {getPlural(extendData?.cancelledOrders?.in_deilvery_count, 'товар', 'товара', 'товаров')}
                                        </h4>
                                    </div>
                                    <div className="info_result">
                                        <h2>{humanNum(extendData?.cancelledOrders?.in_deilvery_sum)}₽</h2>
                                    </div>
                                </> : <SkeletonTheme>
                                    <Skeleton count={1} height="40px" containerClassName="sk_small_flex" />
                                </SkeletonTheme>
                                }
                            </div>

                            <div className="info_item">
                                {!isLoadingED ? <>
                                    <div className="info_symbol">
                                        <MdOutlineCancel className="h-icon" />
                                    </div>
                                    <div className="info_content">
                                        <h3>Отменено</h3>
                                        <h4>{extendData?.cancelledOrders?.cancelled_count || 0}&nbsp;
                                            {getPlural(extendData?.cancelledOrders?.cancelled_count, 'товар', 'товара', 'товаров')}</h4>
                                    </div>
                                    <div className="info_result">
                                        <h2>{humanNum(extendData?.cancelledOrders?.cancelled_sum)}₽</h2>
                                    </div>
                                </> : <SkeletonTheme>
                                    <Skeleton count={1} height="40px" containerClassName="sk_small_flex" />
                                </SkeletonTheme>
                                }
                            </div>

                            <div className="info_item">
                                {!isLoadingED ? <>
                                    <div className="info_symbol">
                                        <BsBoxSeam className="h-icon" />
                                    </div>
                                    <div className="info_content">
                                        <h3>Возвращено</h3>
                                        <h4>{extendData?.cancelledOrders?.return_count || 0}&nbsp;
                                            {getPlural(extendData?.cancelledOrders?.return_count, 'товар', 'товара', 'товаров')}
                                        </h4>
                                    </div>
                                    <div className="info_result">
                                        <h2>{humanNum(extendData?.cancelledOrders?.return_sum)}₽</h2>
                                    </div>
                                </> : <SkeletonTheme>
                                    <Skeleton count={1} height="40px" containerClassName="sk_small_flex" />
                                </SkeletonTheme>
                                }

                            </div>

                        </Container>
                    </Container>
                    <Container className={'w_part_l echart_linear'}>
                        {!isLoading ? <>
                            {
                                isNoData ?
                                    <>
                                        <Container>
                                            <NoData className={'h_400'} >{isNoData}</NoData>
                                        </Container>
                                    </>
                                    :
                                    <EchartDashboard chartData={chartData} selectedStore={selectedStore} />
                            }
                        </> :
                            <SkeletonTheme>
                                <Skeleton height="76px" count={5} containerClassName="e_load_container" style={{ marginBottom: "10px" }} />
                            </SkeletonTheme>
                        }
                    </Container>
                </div >
                {isLoading ? <>
                    <div style={{ marginBottom: '20px' }}>
                        <SkeletonTheme>
                            <Skeleton height="125px" />
                        </SkeletonTheme>
                    </div>
                </> : !isNoData && chartData?.stats?.bar_percent[0] !== 0
                    && chartData?.stats?.bar_percent[0] !== null ?
                    <>
                        <Container className={'m_20 p_20'}>
                            <h2 className="container-title-s">Структура финансов {parseInt(store_id) === 0 && '(прибыль)'}</h2>
                            <HorizontalBar data={{
                                labels: chartData?.stats?.bar_labels,
                                datasets: chartData?.stats?.bar_percent
                            }} />
                        </Container>
                    </>
                    : ''
                }
                <div className="container-row">
                    <Container className={'w_part_l'}>
                        <h3 className="container-title-live-map">
                            <NavLink to={`/orders-map`} >Карта заказов</NavLink> <div className="live_map-circle"></div>
                        </h3>
                        {!isLoading ? <>
                            {
                                isNoData ?
                                    <>
                                        <NoData className={'h_400'} >{isNoData}</NoData>
                                    </>
                                    :
                                    <>
                                        <HighMap
                                            handleGetLastetOrders={handleGetLastetOrders}
                                            toast={toast}
                                            height={'65vh'}
                                            is_short={true}
                                        />
                                    </>
                            }
                        </> : null}
                    </Container>
                    <Container className={'w_part_s grid_v'}>
                        <div className="row">
                            <h3 className="container-title-abc"><NavLink to={`/abc-analysis/${store_id}/page/1/date/${selectedDate?.value || '1m'}`} >ABC анализ</NavLink></h3>
                            {isLoading ?
                                <SkeletonTheme>
                                    <Skeleton count={4} height="20px" style={{ marginBottom: "10px" }} />
                                </SkeletonTheme>
                                :
                                isNoDataED || !extendData?.abc_score ?
                                    <NoData className={'nd_small'} >Нет данных</NoData>
                                    :
                                    <InfoTable
                                        labels={['Группа', 'Товаров, шт.']}
                                        tableData={extendData?.abc_score}
                                        isAbc={true}
                                    />
                            }
                        </div>

                        <div className="row">
                            <h3 className="container-title-abc"><NavLink to={`/abc-analysis/${store_id}/page/1/date/${selectedDate?.value || '1m'}`} >Лидеры продаж</NavLink></h3>
                            {isLoading ?
                                <SkeletonTheme>
                                    <Skeleton count={4} height="30px" style={{ marginBottom: "10px" }} />
                                </SkeletonTheme>
                                :
                                isNoDataED || !extendData?.abc_score ?
                                    <NoData className={'nd_small'} >Нет данных</NoData>
                                    :
                                    <InfoTable
                                        labels={['SKU', 'Заказы, руб.']}
                                        tableData={extendData?.top_items}
                                        humanNum={humanNum}
                                    />
                            }
                        </div>
                    </Container>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
import './NoData.css';

const NoData = ({ children, ...style }) => {
    let { className } = style;

    return (
        <section className={`no_data ${className || ''}`}>
            {children}
        </section>
    );
};

export default NoData;
import './App.css';
import { Route, Routes, useLocation, useNavigate, Navigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";
import { YMInitializer } from 'react-yandex-metrika';

import Api from '../../utils/MainApi.js';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute.js';
import Footer from '../Footer/Footer.jsx';
import NotFound from '../NotFound/NotFound.jsx';

import Login from '../Auth/Login/Login.jsx';
import Register from '../Auth/Register/Register.jsx';
import ResetPassword from '../Auth/ResetPassword/ResetPassword.jsx';

import Account from '../Account/Account.jsx';
import Dashboard from '../Dashboard/Dashboard.jsx';
import Orders from '../Orders/Orders.jsx';
import MyStores from '../MyStores/MyStores.jsx';
import NewStore from '../NewStore/NewStore.jsx';
import EditStore from '../EditStore/EditStore.jsx';
import Items from '../Items/Items.jsx';
import AbcAnalysis from '../Abc-analysis/Abc-analysis.jsx';
import OrdersMap from '../OrdersMap/OrdersMap.jsx';
import Activate from '../SpecialPage/Activate.jsx';
import Tariffs from '../Tariffs/Tariffs.jsx';
import TariffsTb from '../Tariffs/Tariffs-tb.jsx';

import { useLocalStorage } from "../../utils/hooks/localstorage"
import { ToastContainer, toast } from 'react-toastify';
import AppCommon from './App.js';

import 'react-toastify/dist/ReactToastify.css';

function Application() {
  const api = new Api();
  const App = new AppCommon();
  const navigate = useNavigate();

  const [currentUser, setCurrentUser] = React.useState({
    "user_info": {
      "email": null,
      "is_activated": false,
    },
    "subscribe": {
      "is_active": false,
      "package_id": 0,
      "expires_at": null
    },
    stores: []
  });

  // Функция для получения значения куки по её имени
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  }

  function deleteCookie(name) {
    document.cookie = `${name}=; Max-Age=-1; path=/; domain=.ziffar.ru; secure;`;
  }

  const [isLoggedIn, setIsLoggedIn] = React.useState(() => {
    const csrfToken = getCookie('csrf');  // Получаем куку с именем 'csrf'

    return csrfToken !== null;  // Если кука найдена, пользователь авторизован
  });

  const [userStores, setUserStores] = React.useState(false);
  const [selectedStore, setSelectedStore] = useLocalStorage('selectedStore', 0);
  const [selectedDate, setSelectedDate] = useLocalStorage('selectedDate', { value: '1m', label: '1 месяц' });
  const [isLoading, setIsLoading] = useState(true);
  const [didMount, setDidMount] = useState(false)
  const [isUrlMatch, setIsUrlMatch] = useState(false);
  const [isManualSet, setIsManualSet] = useState(false);
  const [autoSwitchStore, setAutoSwitchStore] = useLocalStorage('autoSwitchStore', 0);

  const path = useLocation()?.pathname;

  const refreshStores = async () => {
    await api.getUserStores().then(async (data) => {
      setUserStores(data?.result);
    }).catch((err) => {
      console.log(err);
    });
  }

  const setSelectedStoreState = (value) => {
    setIsManualSet(true);  // Устанавливаем флаг, что вызов был вручную
    setSelectedStore(value);
  };

  const setSelectedDateState = (value) => {
    setIsManualSet(true);  // Устанавливаем флаг, что вызов был вручную
    setSelectedDate(value);
  };

  useEffect(() => { setDidMount(true) }, [userStores])

  useEffect(() => {
    try {
      let urlType;
      let p = 'page';
      let q = 1;
      let d = '';

      if (currentUser.stores.length === 0) {
        return;
      }

      let url_store_id = Number(path.match(/\/(\d+)/)?.[1]);
      let url_date = path.match(/\/date\/(7d|14d|1m|3m|6m|1y)/)?.[1];

      if (url_store_id !== selectedStore.value && !isManualSet) {
        const foundStore = currentUser.stores.find(store => store.value === url_store_id);

        if (foundStore) {
          setSelectedStoreState(foundStore);
        }
      }

      if (url_date !== selectedDate.value && !isManualSet) {
        const options = [
          { value: '7d', label: '7 дней' },
          { value: '14d', label: '14 дней' },
          { value: '1m', label: '1 месяц' },
          { value: '3m', label: '3 месяца' },
          { value: '6m', label: '6 месяцев' },
          { value: '1y', label: '1 год' },
        ];
        const foundDate = options.find(e => e.value === url_date);

        if (foundDate) {
          setSelectedDateState(foundDate);
        }
      }

      // Определите urlType в зависимости от path
      if (path.startsWith('/items')) {
        urlType = '/items';
      } else if (path.startsWith('/orders')) {
        urlType = '/orders';
      } else if (path.startsWith('/abc-analysis')) {
        urlType = '/abc-analysis';
        d = '/date/' + selectedDate.value;
      } else if (path.startsWith('/dashboard')) {
        urlType = '/dashboard';
        p = 'date';
        q = selectedDate.value;
      }

      // Сформируйте новый URL и выполните навигацию при необходимости
      if (urlType && typeof selectedStore.value !== 'undefined') {
        const newUrl = `${urlType}/${selectedStore.value}/${p}/${q}${d}`;

        if (didMount && isManualSet && newUrl !== path) {
          navigate(newUrl);
          if (isManualSet) {
            setIsManualSet(false);  // Сбрасываем флаг после вызова useEffect
            return;
          }
        }
      }
    } catch (e) {
      console.log(e)
    }
  }, [path, selectedStore.value, selectedDate.value, isUrlMatch, didMount, navigate, currentUser.stores]); // Исправлены зависимости

  useEffect(() => {
    console.log(isLoggedIn)
    if (isLoggedIn) {
      Promise.all([api.getUserInfo(), api.getUserStores()]).then(([userInfo, userStores]) => {
        setCurrentUser(userInfo)
        setIsLoggedIn(true);
        setUserStores(userStores.result);

        if (
          userStores?.result?.length === 0 &&
          userInfo?.subscribe?.is_active &&
          /^\/(dashboard|items|orders|abc-analysis)/.test(path)
        ) {
          navigate('/myStores')
        } else if (
          !userInfo?.subscribe?.is_active
        ) {
          if (/^\/(activate)/.test(path) == false) {
            navigate('/tariffs')
          }
        }
      }).catch((error) => {
        if (error?.statusCode === 401) {
          setIsLoggedIn(false);
          deleteCookie('csrf');
          navigate('/signin');
        } else {
          console.log(error)
        }
      })
    }
  }, [isLoggedIn]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (autoSwitchStore === 0 && currentUser.stores.length > 1) {
      let lastStore = currentUser.stores.sort((a, b) => b.value - a.value)[0];

      setAutoSwitchStore(true);
      setSelectedStoreState(lastStore);
    }
  }, [currentUser.stores])

  const navigateTo = (pathPrefix, p = 'page', q = '1') => {
    return <Navigate to={`${pathPrefix}${selectedStore?.value ?? '0'}/${p}/${q}`} replace={true} />;
  };

  return (
    <>
      <Routes>
        <Route path="/" replace element={navigateTo('/dashboard/', 'date', selectedDate.value || '1M')} />
        <Route path="/signin" element={
          <Login
            handleForm={App.handleSignIn}
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            setCurrentUser={setCurrentUser}
          />
        } />
        <Route path="/signup" element={
          <Register
            isLoggedIn={isLoggedIn}
            handleForm={App.handleSignUp}
            setIsLoggedIn={setIsLoggedIn}
            setCurrentUser={setCurrentUser}
          />
        } />
        <Route path="/reset-password" element={
          <ResetPassword
            isLoggedIn={isLoggedIn}
            handleForm={App.handleResetPasswordRequest}
            setIsLoggedIn={setIsLoggedIn}
            setCurrentUser={setCurrentUser}
          />
        } />
        <Route path="/reset-password/:uuid" element={
          <ResetPassword
            isLoggedIn={isLoggedIn}
            handleForm={App.handleResetPassword}
            setIsLoggedIn={setIsLoggedIn}
            setCurrentUser={setCurrentUser}
          />
        } />
        <Route path="/account"
          element={
            <ProtectedRoute
              element={Account}
              isLoggedIn={isLoggedIn}
              currentUser={currentUser}
              selectedStore={selectedStore}
              selectedDate={selectedDate}
              getTgToken={App.handleGetTgToken}
              getTgSettings={App.handleGetTgSettings}
              setTgSettings={App.handleSetTgSettings}
              getUserTransactions={App.handleGetUserTransactions}
              toast={toast}
            />
          }
        />
        <Route path="/tariffs"
          element={
            <ProtectedRoute
              element={Tariffs}
              isLoggedIn={isLoggedIn}
              currentUser={currentUser}
              selectedStore={selectedStore}
              selectedDate={selectedDate}
              toast={toast}
              navigate={navigate}
              getTariffsCalculated={App.handleGetTariffsCalculated}
            />
          }
        />
        <Route path="/dashboard/:store_id/date/:date_range"
          element={
            <ProtectedRoute
              element={Dashboard}
              isLoggedIn={isLoggedIn}
              currentUser={currentUser}
              selectedStore={selectedStore}
              setSelectedStore={setSelectedStoreState}
              getChartOrders={App.handleGetChartOrders}
              getDashboardExtended={App.handleGetDashboardExtended}
              handleGetLastetOrders={App.handleGetLastetOrders}
              toast={toast}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDateState}
              setIsUrlMatch={setIsUrlMatch}
              isUrlMatch={isUrlMatch}
            />
          }
        />
        <Route path="/orders/:store_id/page/:page"
          element={
            <ProtectedRoute
              element={Orders}
              isLoggedIn={isLoggedIn}
              currentUser={currentUser}
              getUserOrders={App.handleGetUserOrders}
              selectedStore={selectedStore}
              setSelectedStore={setSelectedStoreState}
              selectedDate={selectedDate}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          }
        />
        <Route path="/myStores"
          element={
            <ProtectedRoute
              element={MyStores}
              isLoggedIn={isLoggedIn}
              toast={toast}
              currentUser={currentUser}
              refreshStores={refreshStores}
              userStores={userStores}
              handleDeleteUserStore={App.handleDeleteUserStore}
              selectedStore={selectedStore}
              selectedDate={selectedDate}
            />
          }
        />

        <Route path="/orders-map"
          element={
            <ProtectedRoute
              element={OrdersMap}
              isLoggedIn={isLoggedIn}
              currentUser={currentUser}
              handleGetLastetOrders={App.handleGetLastetOrders}
              toast={toast}
              selectedStore={selectedStore}
              selectedDate={selectedDate}
            />
          }
        />

        <Route path="/abc-analysis/:store_id/page/:page/date/:date_range"
          element={
            <ProtectedRoute
              element={AbcAnalysis}
              toast={toast}
              isLoggedIn={isLoggedIn}
              currentUser={currentUser}
              userStores={userStores}
              handleAbcAnalysis={App.handleAbcAnalysis}
              selectedStore={selectedStore}
              setSelectedStore={setSelectedStoreState}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDateState}
            />
          }
        />

        <Route path="/newStore"
          element={
            <ProtectedRoute
              element={NewStore}
              toast={toast}
              isLoggedIn={isLoggedIn}
              currentUser={currentUser}
              handleNewUserStore={App.handleNewUserStore}
              selectedStore={selectedStore}
              selectedDate={selectedDate}
            />
          }
        />
        <Route path="/editStore/:store_id"
          element={
            <ProtectedRoute
              element={EditStore}
              toast={toast}
              isLoggedIn={isLoggedIn}
              currentUser={currentUser}
              handleEditUserStore={App.handleEditUserStore}
              userStores={userStores}
              selectedStore={selectedStore}
              selectedDate={selectedDate}
            />
          }
        />
        <Route path="/items/:store_id/page/:page"
          element={
            <ProtectedRoute
              element={Items}
              toast={toast}
              isLoggedIn={isLoggedIn}
              currentUser={currentUser}
              selectedStore={selectedStore}
              setSelectedStore={setSelectedStoreState}
              getUserItems={App.handleGetUserItems}
              setCostPrice={App.handleSetCostPrice}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              selectedDate={selectedDate}
            />
          }
        />
        <Route path="/activate/:token"
          element={
            <ProtectedRoute
              element={Activate}
              handleUserActivation={App.handleUserActivation}
              isLoggedIn={isLoggedIn}
              currentUser={currentUser}
              selectedDate={selectedDate}
              selectedStore={selectedStore}
            />
          }
        />
        <Route path="*"
          element={
            <ProtectedRoute
              element={NotFound}
              isLoggedIn={isLoggedIn}
              currentUser={currentUser}
              selectedStore={selectedStore}
            />
          } />
        <Route path="/items" replace element={navigateTo('/items/')} />
        <Route path="/orders" replace element={navigateTo('/orders/')} />
        <Route path="/dashboard" replace element={navigateTo('/dashboard/', 'date', selectedDate.value || '1M')} />
      </Routes>
      <ToastContainer />
      <YMInitializer accounts={[98649792]} options={{ webvisor: true }} />
      <Footer path={path} />
    </>
  )
}

export default Application;

import React from "react";
import Header from '../Header/Header';
import Container from '../Container/Container';
import HighMap from './HighMap/HighMap.jsx';
import AlertService from '../AlertService/AlertService.jsx';

import './ordersmap.css';

const OrdersMap = ({ currentUser, selectedStore, handleGetLastetOrders, toast, selectedDate }) => {
    return (
        <>
            <Header
                selectedStore={selectedStore}
                selectedDate={selectedDate}
            />
            <Container className={"app__container-margin-s"}>
                <AlertService
                    currentUser={currentUser}
                />

                <HighMap
                    handleGetLastetOrders={handleGetLastetOrders}
                    toast={toast}
                    height={"100vh"}
                    is_short={false}
                />
            </Container>
        </>
    );
};

export default OrdersMap;

import "./Container.css";

const Container = ({ children, ...style }) => {
    let { className } = style;

    return (
        <section className={`container ${className || ''}`}>
            {children}
        </section>
    );
};

export default Container;
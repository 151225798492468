import './Store-list.css';

const StoreList = ({ StoreList, setTgSettings, toast }) => {
    let stores = '';
    const mp_lits = {
        0: "WB",
        1: "OZON",
        2: "Yandex",
    }

    function handleCheckboxChange(e) {
        const optionData = JSON.parse(e.target.getAttribute('data-s'));
        const isChecked = e.target.checked;
        const id = toast.loading("Сохраняем..");

        setTgSettings({ store_id: optionData.store_id, mark_id: optionData.mark_id, is_on: isChecked }).then((data) => {
            toast.update(id, {
                render: "Изменения приняты",
                autoClose: 5000, type: "success", icon: "🚀",
                isLoading: false, hideProgressBar: false, closeOnClick: true,
                pauseOnHover: true, draggable: true
            });
        }).catch((err) => {
            if (err.statusCode !== 404) {
                toast.update(id, {
                    render: err.message, autoClose: 5000, type: "error", icon: "🤯",
                    isLoading: false, hideProgressBar: false, closeOnClick: true,
                    pauseOnHover: true, draggable: true
                });
            }
        })

    }

    if (StoreList) {
        stores = StoreList.map((store) => {
            return (
                <div className="store_l" key={store.store_id}>
                    <div className="store__name">{store.store_name} ({mp_lits[store.store_type]})</div>
                    <div className='toogle-label'>
                        <div class="toggle-switch">
                            <input class="toggle-input" id={`toggle_${store.store_id}`} data-s={
                                JSON.stringify({ store_id: store.store_id, mark_id: 0 }
                                )} type="checkbox" onChange={handleCheckboxChange} defaultChecked={store.is_activate} />
                            <label class="toggle-label" for={`toggle_${store.store_id}`}></label>
                        </div>
                        Новые заказы FBO и FBS
                    </div>
                </div>
            )
        })
    }

    return (
        stores
    )
}

export default StoreList;
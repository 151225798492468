import Header from '../Header/Header';
import Container from '../Container/Container';

import "../Icon/Icon.css";
import "./MyStores.css";
import "../Button/Button.css";

import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams } from "react-router-dom";
import { PiPencilSimpleLineLight, PiTrash, PiPlusBold } from "react-icons/pi";
import SweetAlert2 from 'react-sweetalert2';

import { getPlural } from '../../utils/plural';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Alert from "../Alert/Alert";

const MyStores = ({ toast, selectedDate, refreshStores, userStores, handleDeleteUserStore, selectedStore }) => {
    let stores;
    const [swalProps, setSwalProps] = useState({});
    const [actionStore, setActionStore] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const deleteUserStore = async (store_id) => {
        const id = toast.loading("Удаляем магазин..");

        handleDeleteUserStore({ store_id }).then(async function (response) {
            toast.update(id, {
                render: response.message,
                autoClose: 5000, type: "success", icon: "🚀",
                isLoading: false, hideProgressBar: false, closeOnClick: true,
                pauseOnHover: true, draggable: true
            });
            refreshStores();
        }).catch(err => {
            toast.update(id, {
                render: err.message,
                autoClose: 5000, type: "error", icon: "🤯",
                isLoading: false, hideProgressBar: false, closeOnClick: true,
                pauseOnHover: true, draggable: true
            });
        })
    }

    useEffect(() => {
        if (userStores) {
            setIsLoading(false);
        }
    }, [userStores]);

    useEffect(() => {
        refreshStores();
    }, []);

    if (!isLoading && userStores.length >= 1) {
        stores = userStores.map((item) => {
            return (
                <>
                    <div className="store">
                        <div className="store__body">
                            <span className={"store__icon " +
                                (item.store_type === 1 ? "store__icon-ozon" : item.store_type == 2 ? "store__icon-yam" : "store__icon-wb")}></span>
                            <span className="store__title">{item.store_name}</span>
                        </div>
                        <ul className="store__health">
                            <li>{item?.substatus ? item?.substatus : item.is_active ? 'Активен' : 'Отключен'}</li>
                            <li>Статус</li>
                            <li>{item.gov_taxes}%</li>
                            <li>Налоговая ставка</li>
                        </ul>
                        <div className="store__actions">
                            <NavLink to={`/editStore/${item.store_id}`} >
                                <button className="button button__muted button__size_xs store-action-icon">
                                    <PiPencilSimpleLineLight size={19} />
                                </button>
                            </NavLink>
                            <button className="button button__muted button__size_xs store-action-icon"
                                onClick={() => {
                                    setActionStore(item.store_id);
                                    setSwalProps({
                                        show: true,
                                        title: `Удалить магазин ${item.store_name} ?`,
                                        showDenyButton: true,
                                        showCancelButton: true,
                                        showConfirmButton: false,
                                        denyButtonText: "Удалить магазин",
                                        cancelButtonText: "Отменить",
                                    });
                                }}
                            >
                                <PiTrash size={19} />
                            </button>
                        </div>
                    </div>
                </>
            );
        });
    }

    const [searchParams] = useSearchParams();
    const message = searchParams.get('message');

    return (
        <>
            <Header
                selectedDate={selectedDate}
                selectedStore={selectedStore}
            />
            <div className="app__container app__container-margin app__container-notify_parent">
                {message && message === 'success' &&
                    <Alert
                        alert={{
                            isDisplay: true,
                            title: 'Магазин успешно добавлен!',
                            message: 'Мы начали процесс синхронизации вашего магазина с нашей системой. Это может занять несколько минут.',
                            type: 'success-light'
                        }}
                    />
                }
                {isLoading ?
                    <SkeletonTheme baseColor="#e0e0e0" highlightColor="#f1f1f1">
                        <Skeleton height="2.5rem" width={300} />
                        <div className='skeleton-container'>
                            <Skeleton height="10rem" width={298} count={2} inline={true} style={{ 'margin-top': "2rem" }} />
                        </div>
                    </SkeletonTheme>
                    :
                    <>
                        <h1>У вас {userStores.length} {getPlural(userStores.length, 'магазин', 'магазина', 'магазинов')}</h1>

                        {!isLoading && (userStores && userStores.length >= 1) ?
                            <>
                                <div class="stores-container">
                                    {stores}
                                    <NavLink to="/newStore" >
                                        <div className="store store__action-new">
                                            <PiPlusBold color={"#a8a8a8"} size={29} /> Добавить магазин
                                        </div>
                                    </NavLink>
                                </div>
                            </>
                            :
                            <>
                                <Container>
                                    <div className="stores_not-found">
                                        <div className="new_ideas"></div>
                                        <h1 className="no-stores-title">У вас пока нет магазинов</h1>
                                        <span>Добавьте магазин чтобы начать работу с сервисом.</span>
                                        <NavLink to="/newStore" >
                                            <button className="button button_new-store">Добавить магазин</button>
                                        </NavLink>
                                    </div>
                                </Container>
                            </>
                        }

                        <SweetAlert2 {...swalProps}
                            didClose={() => {
                                setSwalProps({
                                    show: false
                                })
                            }}
                            onResolve={result => {
                                if (result.isDenied) {
                                    deleteUserStore(actionStore);
                                }
                            }}
                        />
                    </>
                }

            </div >
        </>
    );
};

export default MyStores;